import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/index/App';
import reportWebVitals from './reportWebVitals';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


Bugsnag.start({
    apiKey: 'a65811fd8ffb773194de01850ed0490f',
    plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({apiKey: 'a65811fd8ffb773194de01850ed0490f'})
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <App/>
        </ErrorBoundary>
    </React.StrictMode>
);

reportWebVitals();
