import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../../commons/functionals/authLogic/AuthContext";
import AdminService from "../../../../services/AdminService";
import Firm from "../../../../models/Firm";
import {IoAdd} from "react-icons/io5";
import {
    AiDocumentCheckerSubscriptionDetailsServer
} from "../../../../models/responses/AiDocumentCheckerSubscriptionDetailsServer";
import {
    AiDocumentCheckerCreditsDetailsServer
} from "../../../../models/responses/AiDocumentCheckerCreditsDetailsServer";
import CreateSubscriptionRequest from "../../../../models/requests/CreateSubscriptionRequest";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {toast, Toaster} from "react-hot-toast";


interface ModalInterface {
    slug: string;
    for: string;
}

export default function DashboardFirmsPage() {

    const {user} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const [firms, setFirms] = useState<Firm[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [openedModalSlug, setOpenedModalSlug] = useState<ModalInterface | null>(null);
    const [listSubscriptions, setListSubscriptions] = useState<AiDocumentCheckerSubscriptionDetailsServer[]>([]);
    const [listCredits, setListCredits] = useState<AiDocumentCheckerCreditsDetailsServer[]>([]);
    const adminService = new AdminService();
    const [createSubscriptionRequest, setCreateSubscriptionRequest] = useState<CreateSubscriptionRequest | null>(null);
    const [selectedSubscriptionSlug, setSelectedSubscriptionSlug] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<number | null>(null);
    const [selectedCreditsSlug, setSelectedCreditsSlug] = useState<string | null>(null);

    useEffect(() => {
        if (user !== null) {
            adminService.getFirms(currentPage).then((pageOfUser) => {
                setFirms(pageOfUser.content);
                setTotalPages(pageOfUser.totalPages);
            }).catch((_) => {
                setHasError(true);
            }).finally(() => setIsLoading(false));

            adminService.getAiDocumentCheckerSubscriptions()
                .then((response) => setListSubscriptions(response))
                .catch((_) => setHasError(true));

            adminService.getAiDocumentCheckerCredits()
                .then((response) => setListCredits(response))
                .catch((_) => setHasError(true));
        }
    }, [user, currentPage]);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleUpdateCreateRequest = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCreateSubscriptionRequest((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }) as CreateSubscriptionRequest);
    }

    const addSelectedSubscriptionSlug = (slug: string) => {
        setCreateSubscriptionRequest((prev) => ({
            ...prev,
            selectedSubscriptionSlug: slug,
        }) as CreateSubscriptionRequest);
    }

    const addDate = (date: number) => {
        setCreateSubscriptionRequest((prev) => ({
            ...prev,
            expirationDate: date
        }) as CreateSubscriptionRequest);
    }

    const createSubscription = () => {
        if (createSubscriptionRequest == null) return;

        adminService.createSubscription(openedModalSlug?.slug ?? "", createSubscriptionRequest)
            .then((_) => setOpenedModalSlug(null))
            .catch((_) => toast.error("Error"))
    }

    const addCredits = () => {
        if (selectedCreditsSlug == null) return;

        adminService.addCreditsToSubscription(openedModalSlug?.slug ?? "", selectedCreditsSlug)
            .then((_) => setOpenedModalSlug(null))
            .catch((_) => toast.error("Error"))
    }

    return (
        <>
            <div><Toaster/></div>
            <h1 className={"my-4 text-2xl font-semibold"}>All firms</h1>
            {
                isLoading ?
                    <div role="status" className="max-w-sm animate-pulse">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                    </div> :
                    hasError ?
                        <div>err</div> :
                        firms && firms.length > 0 ? (
                            <>
                                <table className="min-w-full bg-white border border-gray-300">
                                    <thead>
                                    <tr>
                                        <th className="py-2 px-4 border-b">Firm Name</th>
                                        <th className="py-2 px-4 border-b">CUI</th>
                                        <th className="py-2 px-4 border-b">Address</th>
                                        <th className="py-2 px-4 border-b">Firm admin email</th>
                                        <th className="py-2 px-4 border-b">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {firms.map((firm: Firm) => (
                                        <tr key={firm.slug}>
                                            <td className="py-2 px-4 border-b text-center">{firm.name}</td>
                                            <td className="py-2 px-4 border-b text-center">{firm.cui ?? '-'}</td>
                                            <td className="py-2 px-4 border-b text-center">{firm.address}</td>
                                            <td className="py-2 px-4 border-b text-center">{
                                                firm.firmAdmin?.email ??
                                                <span className={"text-error"}>No admin</span>
                                            }</td>
                                            <td>
                                                {
                                                    firm.subscriptionsCode.length === 0
                                                        ?
                                                        <button
                                                            onClick={() => setOpenedModalSlug({
                                                                slug: firm.slug,
                                                                for: "firm"
                                                            })}
                                                            className={"p-2 rounded-lg bg-accent-main-500 text-white flex flex-row gap-2"}>
                                                            <IoAdd/>
                                                            <p>Add Subscription</p>
                                                        </button>
                                                        : <></>
                                                }
                                                {
                                                    firm.subscriptionsCode.map(
                                                        el => <button
                                                            onClick={() => setOpenedModalSlug({
                                                                slug: el.slug,
                                                                for: "subscription"
                                                            })}
                                                            className={"p-2 rounded-lg bg-accent-main-500 text-white flex flex-row gap-2"}>
                                                            <IoAdd/>
                                                            <p>Add Credits for: {el.applicationName}</p>
                                                        </button>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                                <div className="flex justify-between mt-4">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 0}
                                        className="bg-accent-main-500 text-white py-2 px-4 rounded"
                                    >
                                        Previous
                                    </button>
                                    <span className="text-gray-600">
                                        Page {currentPage + 1} of {totalPages}
                                    </span>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages - 1}
                                        className="bg-accent-main-500 text-white py-2 px-4 rounded"
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="text-center text-gray-600">No firms found.</div>
                        )
            }
            {
                openedModalSlug != null && openedModalSlug.for === "firm"
                    ? <div
                        className={"inset-0 fixed z-30 w-screen h-screen flex justify-center items-center bg-opacity-50 bg-black"}>
                        <div className={"md:w-1/3 w-4/5 h-fit bg-white rounded-lg flex flex-col gap-3"}>
                            <button onClick={() => setOpenedModalSlug(null)}>
                                Close
                            </button>
                            <p>This is the id of the firm to be added the subscription: {openedModalSlug.slug}</p>
                            <div>
                                Number of Users:
                                <input type={"number"} name="numberOfUsers" step={1} min={4}
                                       onChange={handleUpdateCreateRequest}/>
                            </div>
                            <div>
                                Number of storage:
                                <input type={"number"} name="storageSpace" step={1} min={0} max={4}
                                       onChange={handleUpdateCreateRequest}/>
                            </div>
                            <div>
                                End date:
                                <DatePicker selected={selectedDate == null ? new Date() : new Date(selectedDate)}
                                            onChange={(date) => {
                                                addDate((date ?? new Date()).getTime());
                                                setSelectedDate((date ?? new Date()).getTime());
                                            }}/>
                            </div>
                            <div>
                                Subscription type:
                                <select value={selectedSubscriptionSlug ?? ''}
                                        onChange={(e) => {
                                            addSelectedSubscriptionSlug(e.target.value);
                                            setSelectedSubscriptionSlug(e.target.value);
                                        }}>
                                    {
                                        listSubscriptions.map((el) =>
                                            <option value={el.slug}>
                                                {el.displayName}
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                            <button onClick={createSubscription}>
                                Create
                            </button>
                        </div>
                    </div>
                    : <></>
            }
            {
                openedModalSlug != null && openedModalSlug.for === "subscription"
                    ? <div
                        className={"inset-0 fixed z-30 w-screen h-screen flex justify-center items-center bg-opacity-50 bg-black"}>
                        <div className={"md:w-1/3 w-4/5 h-fit bg-white rounded-lg flex flex-col gap-3"}>
                            <button onClick={() => setOpenedModalSlug(null)}>
                                Close
                            </button>
                            <p>This is the id of the subscription to be added the credits: {openedModalSlug.slug}</p>
                            <div>
                                Credits package type:
                                <select value={selectedCreditsSlug ?? ''}
                                        onChange={(e) => {
                                            setSelectedCreditsSlug(e.target.value);
                                        }}>
                                    {
                                        listCredits.map((el) =>
                                            <option value={el.slug}>
                                                {el.packageDisplayName} - (Credite: {el.creditAmount})
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                            <button onClick={addCredits}>
                                Add
                            </button>
                        </div>
                    </div>
                    : <></>
            }
        </>
    )
}