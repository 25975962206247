import SubscriptionStatisticsType from "../../../models/SubscriptionStatisticsType";
import SubscriptionUsage from "../../../models/SubscriptionUsage";
import React from "react";
import {AxiosError} from "axios";
import CreateProviderRequest from "../../../models/requests/CreateProviderRequest";
import ProviderContact from "../../../models/ProviderContact";

export default class Generals {

    static getSystemStatusDescription(status: SubscriptionStatisticsType): string {
        let subscriptionDescriptions: Record<SubscriptionStatisticsType, string> = {
            [SubscriptionStatisticsType.creditsUsage]: "Credite ramase",
            [SubscriptionStatisticsType.memoryUsage]: "Memorie folosita",
            [SubscriptionStatisticsType.usersUsage]: "Numar de utilizatori"
        };

        return subscriptionDescriptions[status];
    }

    static getSystemStatusCorrectDisplay(status: SubscriptionUsage) {
        switch (status.title) {
            case SubscriptionStatisticsType.creditsUsage:
                return <p>{status.usage} {status.total == null ? "" : ("/ " + status.total)}</p>
            case SubscriptionStatisticsType.memoryUsage:
                return <div
                    className="w-full bg-gray-200 rounded-full h-fit p-2">
                    <div
                        className="bg-accent-main-500 h-4 rounded-full transition-all duration-300"
                        style={{width: `${parseFloat(status.usage) * 100}%`}}
                    />
                </div>;
            case SubscriptionStatisticsType.usersUsage:
                return <div
                    className="w-full bg-gray-200 rounded-full h-fit p-2">
                    <div
                        className="bg-accent-main-500 h-4 rounded-full transition-all duration-300"
                        style={{width: `${parseFloat(status.usage) / parseFloat(status.total ?? 0) * 100}%`}}
                    />
                </div>;
            default:
                return <></>;
        }
    }

    static buildTextForEducationCode(code: number) {
        switch (code) {
            case 1:
                return "";

            default:
                return "";
        }
    }

    static buildTextForOcrLabelValue = (label: string) => {
        switch (label) {
            case "nr_de_ordine_registrul_comertului":
                return "Numar Registrul Comertului";

            case "cod_caen":
                return "Cod Caen";

            case "nr":
                return "Numar ";

            case "activitate_principala":
                return "Activitate principala";

            case "cod_unic_de_inregistrare":
                return "Cod unic de inregistrare";

            case "euid":
                return "J Reg";

            case "data_infintare":
                return "Data infiintare";

            case "oficiul_registrului":
                return "Oficiul inregistrarii";

            case "seria":
                return "Serie";

            case "sediu_social":
                return "Sediu social";

            case "data_eliberarii":
                return "Data eliberarii";

            case "cui_firma":
                return "CUI";

            case "data_finalizare_valabilitate":
                return "Valabil pana la";

            case "emisa_la":
                return "Emisa la";

            case "data_emitere":
                return "Data emitere";

            case "emisa_in":
                return "Emisa in orasul";

            case "nr_licenta":
                return "Numar licenta";

            case "data_incepere_valabilitate":
                return "Valabil de la";

            case "loc_emitere":
                return "Emisa in locul";

            case "nume_firma":
                return "Pentru firma";

            case "adresa_firma":
                return "Adresa firma";

            case "valabila_de_la":
                return "Valabil de la";

            case "firma":
                return "Nume Firma";

            default:
                return "Camp nerecunoscut";
        }
    }

    static buildError = (error: AxiosError) => {
        switch (error.response?.status ?? 0) {
            case 401:
                return "Nu esti autentificat";

            case 403:
                return "Nu ai access la resursa";

            case 406:
                return "Nu ai credite sau spatiu de stocare ramas";

            case 400:
            case 404:
                return "Am intampinat o eroare. Incearca mai tarziu!";

            default:
                return "Am intampinat o eroare";
        }
    }


    static checkIfProviderCreateHasEmptyFields = (createProviderRequest: CreateProviderRequest) => {
        return createProviderRequest.cui === "" ||
            createProviderRequest.name === "" ||
            createProviderRequest.gei === "" ||
            createProviderRequest.city === "" ||
            createProviderRequest.country === "" ||
            createProviderRequest.address === "" ||
            createProviderRequest.county === "";
    }

    static checkIfProviderContactCreateHasEmptyFields = (providerContactDetails: ProviderContact) => {
        return providerContactDetails.email === "" ||
            providerContactDetails.firstName === "" ||
            providerContactDetails.lastName === "" ||
            providerContactDetails.jobPosition === "" ||
            providerContactDetails.mobilePhone === "";
    }

    static debounce = <T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void => {
        let timer: ReturnType<typeof setTimeout>;

        return (...args: Parameters<T>) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

}