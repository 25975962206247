import {AnalysisTaskStages} from "../../../models/AnalyseTask";
import React, {useState} from "react";

interface TabsProps {
    currentStage: AnalysisTaskStages;
    onTabChanged: (index: number) => void;
}

const AiDocumentCheckerTabs: React.FC<TabsProps> = ({currentStage, onTabChanged}) => {

    const tabs: string[] = ["General", "Analiza", "Informații", "Educație"];
    const [selected, setSelected] = useState<number>(0);

    return (
        <div
            className={`overflow-hidden transition-all duration-300 flex justify-center items-center ${currentStage === AnalysisTaskStages.ERROR || currentStage === AnalysisTaskStages.FINISHED ? "opacity-100 h-[76px]" : "opacity-0 h-0"}`}>
            <div
                className={"w-fit bg-white border border-accent-main-500 rounded-lg flex gap-1 p-2"}>
                {
                    tabs.map((tabName, index) =>
                        <button
                            onClick={() => {
                                setSelected(index);
                                onTabChanged(index)
                            }}
                            className={`p-2 rounded-md ${selected === index ? "bg-accent-main-500 text-white" : "text-gray-600 hover:bg-gray-100"}`}>
                            {tabName}
                        </button>
                    )
                }
            </div>
        </div>
    );
};

export default AiDocumentCheckerTabs;