import {AxiosResponse} from "axios";
import Firm from "../models/Firm";
import axiosInstance from "../commons/functionals/authLogic/AxiosInterceptors";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";
import Employee from "../models/Employee";
import CreateSimpleUserRequest from "../models/requests/CreateSimpleUserRequest";
import Application from "../models/Application";
import SubscriptionUsage from "../models/SubscriptionUsage";
import Subscription from "../models/Subscription";
import LoginAsEmployeeRequest from "../models/requests/LoginAsEmployeeRequest";
import UserResponse from "../commons/component/header/functionals/models/UserResponse";
import Triplets from "../models/Triplets";
import {MarketOrderRequest} from "../models/requests/MarketOrderRequest";
import SubscriptionPaymentStatus from "../models/SubscriptionPaymentStatus";
import NetopiaRequest from "../models/NetopiaRequest";
import {AiDocumentCheckerCreditsDetailsServer} from "../models/responses/AiDocumentCheckerCreditsDetailsServer";
import {
    AiDocumentCheckerSubscriptionDetailsServer
} from "../models/responses/AiDocumentCheckerSubscriptionDetailsServer";

export default class FirmService {

    async getFirmDetails(slug: string): Promise<Firm> {
        const response: AxiosResponse<Firm> = await axiosInstance.get<Firm>(apiRoutes.firmsPiece.replace('SLUG', slug));
        return response.data;
    }

    async getEmployeesUsingApp(firmSlug: string, appSlug: string): Promise<Employee[]> {
        const response: AxiosResponse<Employee[]> = await axiosInstance.get<Employee[]>(apiRoutes.firmEmployeesApp.replace("FIRM_SLUG", firmSlug).replace("APP_SLUG", appSlug));
        return response.data;
    }

    async allowAppToUser(firmSlug: string, userSlug: string, appSlug: string): Promise<string> {
        const response: AxiosResponse<string> = await axiosInstance.post<string>(apiRoutes.firmAllowApp.replace("FIRM_SLUG", firmSlug).replace("EMPLOYEE_SLUG", userSlug).replace("APP_SLUG", appSlug));
        return response.data;
    }

    async marketOrder(slug: string, payload: MarketOrderRequest): Promise<NetopiaRequest> {
        const response: AxiosResponse<NetopiaRequest> = await axiosInstance.post<NetopiaRequest>(apiRoutes.marketOrder.replace("SLUG", slug), payload);
        return response.data;
    }

    async removeAppToUser(firmSlug: string, userSlug: string, appSlug: string): Promise<string> {
        const response: AxiosResponse<string> = await axiosInstance.post<string>(apiRoutes.firmDisallowApp.replace("FIRM_SLUG", firmSlug).replace("EMPLOYEE_SLUG", userSlug).replace("APP_SLUG", appSlug));
        return response.data;
    }

    async getFirmUsages(slug: string): Promise<Triplets<string, SubscriptionUsage[], string>[]> {
        const response: AxiosResponse<Triplets<string, SubscriptionUsage[], string>[]> = await axiosInstance.get<Triplets<string, SubscriptionUsage[], string>[]>(apiRoutes.firmUsage.replace("SLUG", slug));
        return response.data;
    }

    async getSubscriptionStatus(slug: string, appSlug: string): Promise<SubscriptionPaymentStatus> {
        const response: AxiosResponse<SubscriptionPaymentStatus> = await axiosInstance.get<SubscriptionPaymentStatus>(apiRoutes.firmSubscriptionStatus.replace("SLUG", slug).replace("APP_SLUG", appSlug));
        return response.data;
    }

    async getFirmSubscriptions(slug: string): Promise<Subscription[]> {
        const response: AxiosResponse<Subscription[]> = await axiosInstance.get<Subscription[]>(apiRoutes.firmSubscription.replace("SLUG", slug));
        return response.data;
    }

    async getMyApps(): Promise<Application[]> {
        const response: AxiosResponse<Application[]> = await axiosInstance.get<Application[]>(apiRoutes.firmApps);
        return response.data;
    }

    async getFirmEmployees(slug: string, name: string): Promise<Employee[]> {
        const response: AxiosResponse<Employee[]> = await axiosInstance.get<Employee[]>(apiRoutes.searchEmployees.replace('SLUG', slug) + name);
        return response.data;
    }

    async deleteEmployee(firmSlug: string, userSlug: string): Promise<void> {
        const response: AxiosResponse<void> = await axiosInstance.delete<void>(apiRoutes.removeFromFirm.replace('SLUG', firmSlug).replace('SLUG_USER', userSlug));
        return response.data;
    }

    async loginAsEmployee(firmSlug: string, request: LoginAsEmployeeRequest): Promise<UserResponse> {
        const response: AxiosResponse<UserResponse> = await axiosInstance.post<UserResponse>(apiRoutes.firmLogin.replace('SLUG', firmSlug), request);
        return response.data;
    }

    async addEmployee(firmSlug: string, payload: CreateSimpleUserRequest): Promise<void> {
        const response: AxiosResponse<void> = await axiosInstance.post<void>(apiRoutes.addToFirm.replace('SLUG', firmSlug), payload);
        return response.data;
    }
}