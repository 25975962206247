import AnalysisDetails from "./AnalysisDetails";

export enum AnalysisTaskStages {
    STARTED = "STARTED",
    STARTED_ANALYSIS = "STARTED_ANALYSIS",
    DOCUMENT_TYPE = "DOCUMENT_TYPE",
    DATA_EXTRACTION = "DATA_EXTRACTION",
    MANIPULATION_DETECTION = "MANIPULATION_DETECTION",
    CLOUD_UPLOADING = "CLOUD_UPLOADING",
    ERROR = "ERROR",
    FINISHED = "FINISHED",
}

export interface AnalyseTask {
    slug: string;
    userSlugInitiator: string;
    analysisTaskStages: AnalysisTaskStages;
    analysisDetails: AnalysisDetails;
    startedAt: Date;
}