import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../commons/functionals/authLogic/AuthContext";
import Application from "../../models/Application";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import FirmService from "../../services/FirmService";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import FirmSettingsRouter from "../../routers/FirmSettingsRouter";
import UserRoles from "../../commons/component/header/functionals/models/UserRoles";
import {IoChevronBack, IoHome} from "react-icons/io5";
import {GiTeacher} from "react-icons/gi";
import DashboardUserRouter from "../../routers/DashboardUserRouter";
import MenuOption from "../../models/MenuOption";
import {FaUsersBetweenLines} from "react-icons/fa6";
import {FaHome, FaMoneyCheck, FaTruck} from "react-icons/fa";
import FirmSettingsRootPage from "../firm/FirmSettingsRootPage";
import DashboardPage from "./views/dashboard/DashboardPage";
import AiDocumentCheckerRouter from "../../routers/AiDocumentCheckerRouter";
import ProviderRouter from "../../routers/ProviderRouter";
import AiDocumentCheckerRoot from "../ai_document_checker/AiDocumentCheckerRoot";
import {GrDocumentUser} from "react-icons/gr";
import ProvidersRootPage from "../providers_dashboard/ProvidersRootPage";
import PrivateAccessRoute from "../../commons/functionals/authLogic/route_permitters/PrivateAccessRoute";
import {LuBrainCircuit} from "react-icons/lu";

export default function DashboardRootPage() {

    const {user} = useContext(AuthContext);
    const [appsIsLoading, setAppsIsLoading] = useState<boolean>(false);
    const [applications, setApplications] = useState<Application[]>([]);
    const [asideWidth, setAsideWidth] = useState<string>("260px");
    const location = useLocation();
    const iconStyling = (path: string) => `w-5 h-5 transition duration-75 group-hover:text-text-main-color-950 ${location.pathname === path ? 'text-accent-main-600' : 'text-text-main-color-500'} group`;
    const actions: MenuOption[] = [
        {
            icon: <IoHome className={iconStyling(ApplicationRoutes.dashboard)}/>,
            name: 'Panou de bord',
            link: ApplicationRoutes.dashboard,
            role: [UserRoles.ROLE_SUPER_ADMIN, UserRoles.ROLE_ACCOUNT_ADMIN, UserRoles.ROLE_EMPLOYEE],
            submenu: null
        },
        {
            icon: <FaTruck className={iconStyling(ApplicationRoutes.dashboard + DashboardUserRouter.Provider)}/>,
            name: 'Furnizori',
            link: ApplicationRoutes.dashboard + DashboardUserRouter.Provider,
            role: [UserRoles.ROLE_ACCOUNT_ADMIN, UserRoles.ROLE_EMPLOYEE],
            submenu: [
                {
                    icon: <LuBrainCircuit
                        className={iconStyling(ApplicationRoutes.dashboard + DashboardUserRouter.Provider + ProviderRouter.search)}/>,
                    name: 'Smart Search',
                    link: ProviderRouter.search,
                    role: [UserRoles.ROLE_EMPLOYEE],
                    submenu: null
                },
            ]
        },
        {
            icon: <GiTeacher className={iconStyling(ApplicationRoutes.dashboard + DashboardUserRouter.firmDetails)}/>,
            name: 'Firma mea',
            link: ApplicationRoutes.dashboard + DashboardUserRouter.firmDetails,
            role: [UserRoles.ROLE_ACCOUNT_ADMIN],
            submenu: [
                {
                    icon: <FaHome
                        className={iconStyling(ApplicationRoutes.dashboard + DashboardUserRouter.firmDetails + FirmSettingsRouter.root)}/>,
                    name: 'Detalii',
                    link: FirmSettingsRouter.root,
                    role: [UserRoles.ROLE_ACCOUNT_ADMIN],
                    submenu: null
                },
                {
                    icon: <FaMoneyCheck
                        className={iconStyling(ApplicationRoutes.dashboard + DashboardUserRouter.firmDetails + FirmSettingsRouter.subscription)}/>,
                    name: 'Abonamente active',
                    link: FirmSettingsRouter.subscription,
                    role: [UserRoles.ROLE_ACCOUNT_ADMIN],
                    submenu: null
                },
                {
                    icon: <FaUsersBetweenLines
                        className={iconStyling(ApplicationRoutes.dashboard + DashboardUserRouter.firmDetails + FirmSettingsRouter.firmUsers)}/>,
                    name: 'Angajati',
                    link: FirmSettingsRouter.firmUsers,
                    role: [UserRoles.ROLE_ACCOUNT_ADMIN],
                    submenu: null
                }
            ]
        },
    ];

    const matchPathWithSlug = (slug: string) => {
        switch (slug) {
            case process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG:
                return {
                    first: <GrDocumentUser
                        className={"w-5 h-5 text-white transition duration-75 group-hover:text-accent-main-900"}/>,
                    second: (ApplicationRoutes.dashboard + DashboardUserRouter.AiDocumentChecker + (user?.role.name === UserRoles.ROLE_ACCOUNT_ADMIN ? AiDocumentCheckerRouter.firmAdminRoot : ""))
                };
        }
        return {first: <></>, second: "/not-found"};
    }

    useEffect(() => {
        setAppsIsLoading(true);
        new FirmService().getMyApps().then((response) => {
            setApplications(response);
        }).catch((_) => {
        }).finally(() => setAppsIsLoading(false));
    }, [user]);

    return (
        <>
            <div className={"bg-gray-100"}>
                <aside id="default-sidebar" className={`fixed h-full bg-gray-50 z-30 w-[${asideWidth}]`}>
                    <div className={"h-[100px] w-full p-3"}>
                        <Link to={"https://inovia-trans.ro"}
                              className={"h-full flex justify-center font-main-medium cursor-pointer rounded-lg"}>
                            <img src="/logo.png" className={"h-full p-0.5"} alt={"logo.png"}/>
                        </Link>
                    </div>
                    <div className="h-full px-3 py-4 overflow-y-auto">
                        <ul className={"justify-center items-center font-main-medium flex-row md:flex-col flex gap-2"}>
                            {
                                actions.map((option, i) =>
                                    !option.role.includes(user!.role.name) ?
                                        <></> :
                                        <li key={i} className={"w-full"}>
                                            <Link to={option.link}
                                                  className={`flex p-2 rounded-lg hover:bg-text-main-color-100 ${location.pathname === option.link ? 'bg-accent-main-100 text-accent-main-600' : 'text-text-main-color-500'} group`}>
                                                <div
                                                    className={"w-full flex md:justify-normal justify-center md:items-start items-center"}>
                                                    {option.icon}
                                                    <span
                                                        className="md:flex ms-3 group-hover:text-text-main-color-950">{option.name}</span>
                                                </div>
                                                {
                                                    option.submenu != null ?
                                                        <p className={"flex justify-center items-center"}><IoChevronBack
                                                            className={`${location.pathname.includes(option.link) ? "-rotate-90" : "rotate-180"}`}/>
                                                        </p> :
                                                        <></>
                                                }
                                            </Link>
                                            <ul className={`ml-5 flex-col ${location.pathname.includes(option.link) ? "flex" : "hidden"}`}>
                                                {
                                                    option.submenu && option.submenu.map((suboption, i) =>
                                                        suboption.role.includes(user!.role.name)
                                                            ? <li key={i} className={"w-full mt-2"}>
                                                                <Link to={option.link + suboption.link}
                                                                      className={`flex p-2 rounded-lg hover:bg-text-main-color-100 ${location.pathname === (option.link + suboption.link) ? 'bg-accent-main-100 text-accent-main-500' : 'text-text-main-color-500'} group`}>
                                                                    {suboption.icon}
                                                                    <span
                                                                        className={`hidden md:flex ms-3 group-hover:text-text-main-color-950`}>{suboption.name}</span>
                                                                </Link>
                                                            </li>
                                                            : <></>
                                                    )
                                                }
                                            </ul>
                                        </li>
                                )
                            }
                        </ul>
                        <hr className={"mt-3 mb-5"}/>
                        <ul>
                            {appsIsLoading ?
                                <div role="status" className="p-8 max-w-sm animate-pulse">
                                    <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
                                    <div
                                        className="h-2 bg-gray-200 rounded-full max-w-[360px] mb-2.5"></div>
                                    <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                                    <div
                                        className="h-2 bg-gray-200 rounded-full max-w-[330px] mb-2.5"></div>
                                    <div
                                        className="h-2 bg-gray-200 rounded-full max-w-[300px] mb-2.5"></div>
                                    <div className="h-2 bg-gray-200 rounded-full max-w-[360px]"></div>
                                </div> :
                                applications.map((app: Application) => {
                                        let data = matchPathWithSlug(app.slug);
                                        return <Link to={data.second} key={app.slug}
                                                     style={{backgroundColor: app.headerColor}}
                                                     className="w-full rounded-lg shadow-lg p-3 mb-2 flex gap-2">
                                            {data.first}
                                            <h2 className="text-lg text-white font-main-medium">{app.name}</h2>
                                        </Link>
                                    }
                                )
                            }
                        </ul>
                    </div>
                </aside>
                <div className={`p-4 pt-[75px] min-h-screen`} style={{paddingLeft: `${asideWidth}`}}>
                    <Routes>
                        <Route path={DashboardUserRouter.root}
                               element={
                                   <PrivateAccessRoute
                                       permittedApplicationAccess={"*"}
                                       permittedRole={[UserRoles.ROLE_EMPLOYEE, UserRoles.ROLE_ACCOUNT_ADMIN]}>
                                       <DashboardPage/>
                                   </PrivateAccessRoute>
                               }/>
                        <Route path={DashboardUserRouter.firmDetails + FirmSettingsRouter.entrance}
                               element={
                                   <PrivateAccessRoute
                                       permittedApplicationAccess={"*"}
                                       permittedRole={[UserRoles.ROLE_ACCOUNT_ADMIN]}>
                                       <FirmSettingsRootPage/>
                                   </PrivateAccessRoute>
                               }/>
                        <Route path={DashboardUserRouter.Provider + ProviderRouter.entrance}
                               element={
                                   <PrivateAccessRoute
                                       permittedApplicationAccess={"*"}
                                       permittedRole={[UserRoles.ROLE_EMPLOYEE, UserRoles.ROLE_ACCOUNT_ADMIN]}>
                                       <ProvidersRootPage/>
                                   </PrivateAccessRoute>
                               }/>
                        <Route path={DashboardUserRouter.AiDocumentChecker + AiDocumentCheckerRouter.entrance}
                               element={
                                   <PrivateAccessRoute
                                       permittedApplicationAccess={process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'hash'}
                                       permittedRole={[UserRoles.ROLE_EMPLOYEE, UserRoles.ROLE_ACCOUNT_ADMIN]}>
                                       <AiDocumentCheckerRoot/>
                                   </PrivateAccessRoute>
                               }/>
                    </Routes>
                </div>
            </div>
        </>
    )
}