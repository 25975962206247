import {HiOutlineSearch} from "react-icons/hi";
import {MdClear} from "react-icons/md";
import Loading from "../../../commons/component/loading/views/Loading";
import {Link} from "react-router-dom";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";
import DashboardUserRouter from "../../../routers/DashboardUserRouter";
import AiDocumentCheckerRouter from "../../../routers/AiDocumentCheckerRouter";
import {LuExternalLink} from "react-icons/lu";
import Utils from "../../../commons/functionals/utils/Utils";
import React, {useContext, useEffect, useState} from "react";
import SearchResult from "../../../models/responses/SearchResult";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import ProvidersService from "../../../services/ProvidersService";

export default function SmartSearchProviders() {

    const {user} = useContext(AuthContext);
    const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [searchedText, setSearchedText] = useState<string>("");
    const service = new ProvidersService();
    // const debouncedSearch = () => Generals.debounce(search, 300);

    useEffect(() => {
        search();
    }, [searchedText]);

    const handleSearch = (searchTerm: string) => {
        setSearchedText(searchTerm);
        // debouncedSearch();
    }

    const search = () => {
        if (user === null)
            return;

        if (searchedText.length === 0)
            return;

        setIsLoadingSearch(true);
        service.search({searchQuery: searchedText, range: null})
            .then((response) => setSearchResults(response))
            .catch((_) => {
            })
            .finally(() => setIsLoadingSearch(false));
    }

    return (
        <div className={"rounded-xl bg-white p-4 flex flex-col justify-center items-center"}>
            <h1 className={"font-main-medium text-2xl pb-3 text-center"}>Smart Search</h1>
            <div
                className="border group bg-white duration-300 focus-within:border-accent-main-500 w-full md:w-2/3 gap-2 rounded-xl flex justify-center items-center">
                <HiOutlineSearch
                    className="text-gray-300 duration-300 group-focus-within:text-accent-main-500 pl-2 w-[30px]"/>
                <input
                    value={searchedText}
                    onChange={(e) => handleSearch(e.target.value)}
                    type="text"
                    className="caret-accent-main-500 w-full outline-none p-2 rounded-full"
                    placeholder="Cauta aici orice detaliu"
                />
                {
                    searchedText.length !== 0 && (
                        <MdClear
                            onClick={() => handleSearch("")}
                            className="text-error pr-2 w-[30px]"
                        />
                    )
                }
            </div>
            <div className={"w-full pt-3"}>
                {
                    isLoadingSearch ?
                        <Loading color={"fill-accent-main-500"}/>
                        : searchResults.map((result, i) =>
                            <div key={i} className={"w-full py-3 px-2"}>
                                <Link
                                    to={ApplicationRoutes.dashboard + DashboardUserRouter.AiDocumentChecker + AiDocumentCheckerRouter.viewTaskEntrance + result.taskSlug}
                                >
                                    <p className={"text-lg flex flex-row gap-2 items-center"}>
                                        Document: {result.documentName}
                                        <LuExternalLink/>
                                    </p>
                                </Link>
                                <p className={"text-sm"}>Tip: {Utils.convertToText(result.documentType)}</p>
                                <p className={"text-sm text-gray"}>...{result.resultText}...</p>
                            </div>
                        )
                }
            </div>
        </div>
    );
}