import {AnalysisTaskStages} from "../../../models/AnalyseTask";
import React from "react";
import Loading from "../../../commons/component/loading/views/Loading";
import {IoCheckmark} from "react-icons/io5";

interface ProgressBarProps {
    currentStage: AnalysisTaskStages;
}

const AiDocumentCheckerProgressBar: React.FC<ProgressBarProps> = ({currentStage}) => {
    const stages = [
        {stage: AnalysisTaskStages.STARTED, title: "Start", width: '5%'},
        {stage: AnalysisTaskStages.STARTED_ANALYSIS, title: "Analiza document", width: '20%'},
        {stage: AnalysisTaskStages.DOCUMENT_TYPE, title: "Detectare tip document", width: '30%'},
        {stage: AnalysisTaskStages.DATA_EXTRACTION, title: "Extragere date", width: '40%'},
        {stage: AnalysisTaskStages.MANIPULATION_DETECTION, title: "Detectare manipulare", width: '70%'},
        {stage: AnalysisTaskStages.CLOUD_UPLOADING, title: "Incarcare in cloud", width: '90%'},
        {stage: AnalysisTaskStages.ERROR, title: "Eroare", width: '100%'},
        {stage: AnalysisTaskStages.FINISHED, title: "Terminat", width: '100%'},
    ];

    const currentWidth = stages.find(s => s.stage === currentStage)?.width || '0%';
    const currentTitle = stages.find(s => s.stage === currentStage)?.title || 'Start';

    return (
        <div
            className={`overflow-hidden transition-all duration-300 ${currentStage === AnalysisTaskStages.ERROR || currentStage === AnalysisTaskStages.FINISHED ? "opacity-0 h-0" : "opacity-100 h-[76px]"}`}>
            <div className={"w-full flex flex-col justify-center items-center p-2"}>
                <p className={"text-black font-bold"}>{currentTitle}</p>
                <div className="flex justify-center items-center w-full bg-gray-200 rounded m-1">
                    <div
                        className={`${currentStage === AnalysisTaskStages.FINISHED || currentStage === AnalysisTaskStages.ERROR ? '' : 'animate-pulse'} transition-all duration-300 text-white py-1 flex justify-center items-center rounded ${currentStage === AnalysisTaskStages.ERROR ? 'bg-red-500' : 'bg-accent-main-500'}`}
                        style={{width: currentWidth}}
                    />
                </div>
                <div className={"flex flex-row justify-center gap-2 items-center"}>
                    {
                        currentStage !== AnalysisTaskStages.ERROR && currentStage !== AnalysisTaskStages.FINISHED
                            ? <Loading color={"fill-accent-main-500"}/>
                            : <IoCheckmark color={"fill-accent-main-500"}/>
                    }
                    <p className={"text-gray-500 text-sm"}>
                        {
                            currentStage !== AnalysisTaskStages.ERROR && currentStage !== AnalysisTaskStages.FINISHED
                                ? "Se analizeaza documentul"
                                : "Analiza completa"
                        }
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AiDocumentCheckerProgressBar;