import {AxiosResponse} from "axios";
import axiosInstance from "../commons/functionals/authLogic/AxiosInterceptors";
import {apiRoutesAiDocumentChecker} from "../commons/environment/EnvironmentRoutes";

export default class BlacklistService {

    async search(firmSlug: string, query: { searchQuery: string }): Promise<string> {
        const response: AxiosResponse<string> = await axiosInstance.post<string>(apiRoutesAiDocumentChecker.blacklistSearch.replace("FIRM_SLUG", firmSlug), query);
        return response.data;
    }
}