import React, {FormEvent, useContext, useState} from "react";
import LoginService from "../../services/LoginService";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Loading from "../../commons/component/loading/views/Loading";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import {AuthContext} from "../../commons/functionals/authLogic/AuthContext";
import UserRoles from "../../commons/component/header/functionals/models/UserRoles";

export default function LoginPage() {

    const {setUser} = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [componentLoading, setComponentLoading] = useState(false);
    const navigate = useNavigate();
    const [next] = useSearchParams();

    function login(event: FormEvent) {
        event.preventDefault();
        setComponentLoading(true);

        new LoginService().login(email, password).then((response) => {
            setUser(response.data);
            navigate(next.get("next") ?? (response.data.role.name === UserRoles.ROLE_SUPER_ADMIN ? ApplicationRoutes.dashboardAdminRoot : ApplicationRoutes.dashboard));
        }).catch((error) => {
            setError((error.response ?? {data: {message: ''}}).data.message);
        }).finally(() => setComponentLoading(false));
    }

    return (
        <div className={"h-screen w-screen flex justify-center items-center bg-gray-100 relative"}>
            <div className="absolute bottom-0 left-0 w-full">
                <div className="absolute bottom-0 left-0 w-full">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                        className="w-full"
                    >
                        <path fill="#fb9b00" fillOpacity="1"
                              d="M0,224L34.3,240C68.6,256,137,288,206,304C274.3,320,343,320,411,282.7C480,245,549,171,617,144C685.7,117,754,139,823,138.7C891.4,139,960,117,1029,101.3C1097.1,85,1166,75,1234,64C1302.9,53,1371,43,1406,37.3L1440,32L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
                    </svg>
                </div>
            </div>
            <div className={"z-10 w-full md:shadow-2xl md:w-1/2 lg:w-1/3 md:bg-white md:rounded-xl p-6"}>
                <h1 className={"pb-4 text-3xl text-center font-main-bold"}>Portal logare</h1>
                <div className="mb-6">
                    <form onSubmit={login} className={"flex justify-center items-center flex-col"}>
                        <input type="text" placeholder="Email"
                               required={true}
                               onChange={(e) => setEmail(e.target.value)}
                               className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-accent-main-500 focus:border-accent-main-500 block w-full p-2.5"/>
                        <input type="password" placeholder="Password"
                               required={true}
                               onChange={(e) => setPassword(e.target.value)}
                               className="mt-5 mb-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-accent-main-500 focus:border-accent-main-500 block w-full p-2.5"/>
                        <div className={"mb-5 text-error"}>
                            {error ?? ""}
                        </div>
                        <button type="submit"
                                disabled={componentLoading}
                                className={"w-32 text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-main-medium rounded-lg text-sm px-5 py-2.5 text-center transition-all flex justify-center items-center"}>
                            {!componentLoading ? "Login" : <Loading/>}
                        </button>
                        <Link to={ApplicationRoutes.register}
                              className={"mt-2 text-accent-main-500 font-main-medium flex justify-center items-center w-full text-center"}>
                            <span>Creaza un cont</span>
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    );
}