import Provider from "../models/Provider";
import {AxiosResponse} from "axios";
import axiosInstance from "../commons/functionals/authLogic/AxiosInterceptors";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";
import CreateProviderRequest from "../models/requests/CreateProviderRequest";
import BuildProviderResponse from "../models/responses/BuildProviderResponse";
import UnlinkProviderRequest from "../models/requests/UnlinkProviderRequest";
import ProviderContact from "../models/ProviderContact";
import SearchResult from "../models/responses/SearchResult";
import ProviderComplete from "../models/ProviderComplete";

export default class ProvidersService {
    async getAllProviders(): Promise<Provider[]> {
        const response: AxiosResponse<Provider[]> = await axiosInstance.get<Provider[]>(apiRoutes.getMyProviders);
        return response.data;
    }

    async getProviderDetails(cui: string): Promise<ProviderComplete> {
        const response: AxiosResponse<ProviderComplete> = await axiosInstance.get<ProviderComplete>(apiRoutes.getProviderDetails.replace("CUI", cui));
        return response.data;
    }

    async updateProvider(cui: string, createRequest: CreateProviderRequest): Promise<string> {
        const response: AxiosResponse<string> = await axiosInstance.put<string>(apiRoutes.editBaseDetails.replace("CUI", cui), createRequest);
        return response.data;
    }

    async addContactToProvider(cui: string, addContactRequest: ProviderContact): Promise<string> {
        const response: AxiosResponse<string> = await axiosInstance.put<string>(apiRoutes.addContactProvider.replace("CUI", cui), addContactRequest);
        return response.data;
    }

    async createProvider(createRequest: CreateProviderRequest): Promise<string> {
        const response: AxiosResponse<string> = await axiosInstance.post<string>(apiRoutes.createProvider, createRequest);
        return response.data;
    }

    async extractProvider(taskSlug: string): Promise<BuildProviderResponse> {
        const response: AxiosResponse<BuildProviderResponse> = await axiosInstance.get<BuildProviderResponse>(apiRoutes.buildProvider.replaceAll("TASK_SLUG", taskSlug));
        return response.data;
    }

    async changeProvider(unlinkProviderRequest: UnlinkProviderRequest): Promise<string> {
        const response: AxiosResponse<string> = await axiosInstance.post<string>(apiRoutes.changeProvider, unlinkProviderRequest);
        return response.data;
    }

    async deleteProvider(cui: string): Promise<string> {
        const response: AxiosResponse<string> = await axiosInstance.delete<string>(apiRoutes.deleteProvider.replace("CUI", cui));
        return response.data;
    }

    async search(query: { searchQuery: string, range: string | null }): Promise<SearchResult[]> {
        const response: AxiosResponse<SearchResult[]> = await axiosInstance.post<SearchResult[]>(apiRoutes.searchGeneralData, query);
        return response.data;
    }
}