import axios, {AxiosResponse} from "axios";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";
import RegisterRequest from "../models/requests/RegisterRequest";
import FirmResponseDetails from "../models/responses/FirmResponseDetails";

export default class RegisterService {

    async register(request: RegisterRequest): Promise<Response> {
        return await axios.post(apiRoutes.register, request, {withCredentials: true});
    }

    async checkCui(cui: string): Promise<FirmResponseDetails> {
        const response: AxiosResponse<FirmResponseDetails> = await axios.post<FirmResponseDetails>(apiRoutes.verifyCui + cui, {withCredentials: true});
        return response.data;
    }

}