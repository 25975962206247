import Loading from "../../../commons/component/loading/views/Loading";
import React, {useContext, useEffect, useState} from "react";
import SubscriptionUsage from "../../../models/SubscriptionUsage";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import FirmService from "../../../services/FirmService";
import {toast, Toaster} from "react-hot-toast";
import Employee from "../../../models/Employee";
import Generals from "../../../commons/component/generals/Generals";
import SubscriptionPaymentStatus from "../../../models/SubscriptionPaymentStatus";
import SubscriptionPaymentStatusEnum from "../../../models/SubscriptionPaymentStatusEnum";
import {Link} from "react-router-dom";
import SubscriptionStatisticsType from "../../../models/SubscriptionStatisticsType";

export default function AiDocumentCheckerFirmAdminDashboard() {

    const {user} = useContext(AuthContext);
    const [usage, setUsage] = useState<SubscriptionUsage[]>([]);
    const [usagesLoading, setUsagesLoading] = useState<boolean>(false);

    const [employeesUsingApp, setEmployeesUsingApp] = useState<Employee[]>([]);
    const [employeesUsingAppLoading, setEmployeesUsingAppLoading] = useState<boolean>(false);


    const [employees, setEmployees] = useState<Employee[]>([]);
    const [employeesLoading, setEmployeesLoading] = useState<boolean>(false);

    const [acceptanceEmployeeLoading, setAcceptanceEmployeeLoading] = useState<boolean>(false);

    const [isLoadingSubscriptionStatus, setIsLoadingSubscriptionStatus] = useState<boolean>(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionPaymentStatus | null>(null);
    const service = new FirmService();

    useEffect(() => {
        if (user === null)
            return;

        setIsLoadingSubscriptionStatus(true);
        service.getSubscriptionStatus(user.firmSlug, (process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none'))
            .then(response => {
                setSubscriptionStatus(response);
                setIsLoadingSubscriptionStatus(false);
            })
            .catch((_) => {
            })

        setUsagesLoading(true);
        service.getFirmUsages(user.firmSlug)
            .then(response => {
                let rest = response.filter((el) => el.additionalParam === (process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none'));
                setUsage(rest.length === 0 ? [] : rest[0].second);
            })
            .catch((_) => toast.error("Am intampinat o eroare la afisarea metricilor. Incearca mai tarziu"))
            .finally(() => setUsagesLoading(false));

        setEmployeesUsingAppLoading(true);
        service.getEmployeesUsingApp(user.firmSlug, process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none').then((response) => {
            setEmployeesUsingApp(response.filter((e) => e.slug !== user.slug));
        }).catch((_) => {
            toast.error("Am intampinat o eroare. Incarca din nou")
        }).finally(() => setEmployeesUsingAppLoading(false));

        setEmployeesLoading(true);
        service.getFirmEmployees(user.firmSlug, "").then((response) => {
            setEmployees(response.filter((e) => e.slug !== user.slug));
        }).catch((_) => {
            toast.error("Am intampinat o eroare. Incarca din nou")
        }).finally(() => setEmployeesLoading(false));
    }, []);

    const allowAppToUser = (userSlug: string) => {
        setAcceptanceEmployeeLoading(true);
        service.allowAppToUser(user!.firmSlug, userSlug, process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none')
            .then((response) => {
                setEmployeesUsingApp([...employeesUsingApp, ...employees.filter((e) => e.slug === userSlug)]);
                toast.success(response);
            })
            .catch((error) => {
                toast.error(error.response.data ?? "Am intampinat o eroare. Incearca mai tarziu.")
            })
            .finally(() => setAcceptanceEmployeeLoading(false));
    }

    const disallowAppToUser = (userSlug: string) => {
        setAcceptanceEmployeeLoading(true);
        service.removeAppToUser(user!.firmSlug, userSlug, process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none')
            .then((_) => {
                setEmployeesUsingApp(employeesUsingApp.filter((e) => e.slug !== userSlug));
                toast.success("Utilizatorul nu mai are access");
            })
            .catch((_) => toast.error("Am intampinat o eroare. Incearca mai tarziu."))
            .finally(() => setAcceptanceEmployeeLoading(false));
    }

    return (
        <>
            <div><Toaster/></div>
            {
                !isLoadingSubscriptionStatus && subscriptionStatus?.subscriptionStatus === SubscriptionPaymentStatusEnum.expired ?
                    <div
                        // onClick={() => window.location.href = `https://${subscriptionStatus.subscriptionPaymentUrl!}`}
                        className={"cursor-pointer p-2 mb-4 bg-red-600 text-white text-center w-full"}>
                        Abonamentul a expirat. Dupa 15 zile vor fi sterse toate datele tale automat.
                        Fa un transfer bancar, iar noi vom confirma comanda ta.
                    </div> : <></>
            }
            {
                !isLoadingSubscriptionStatus && subscriptionStatus?.subscriptionStatus === SubscriptionPaymentStatusEnum.notPaid ?
                    <div
                        // onClick={() => window.location.href = `https://${subscriptionStatus.subscriptionPaymentUrl!}`}
                        className={"cursor-pointer p-2 mb-4 bg-red-600 text-white text-center w-full"}>
                        Mai ai
                        <span className={"font-bold px-2"}>
                            {Math.ceil((subscriptionStatus.timeUtilDeadline - new Date().getTime()) / (1000 * 60 * 60 * 24))}
                        </span>
                        zile pana la expirarea abonamentului.
                        Fa un transfer bancar, iar noi vom confirma comanda ta.
                    </div> : <></>
            }
            {
                !isLoadingSubscriptionStatus && subscriptionStatus?.subscriptionStatus === SubscriptionPaymentStatusEnum.paid ?
                    <div
                        className={"p-2 mb-4 bg-green-500 text-white text-center w-full"}>
                        Abonamentul este valabil pana la data de
                        <span className={"font-bold px-2"}>
                            {new Date(subscriptionStatus.timeUtilDeadline).toLocaleDateString()}
                        </span>
                    </div> : <></>
            }
            <div className={"flex mb-1 justify-between"}>
                <h2 className="text-2xl font-main-bold mr-3">Statistici curente pentru Document Checker:</h2>
            </div>
            <div className="flex flex-row gap-3 mb-4">
                {
                    usagesLoading ?
                        <Loading color={"fill-accent-main-500"}/> :
                        usage.length === 0 ?
                            <p>Nu s-a inregistrat nicio statistica</p> :
                            usage.map(usageRow =>
                                <div className={""}>
                                    <div
                                        className={"flex flex-row gap-4"}>
                                        <div className="bg-white p-4 rounded-md shadow-md">
                                            <h3 className="text-lg font-main-medium mb-2">{Generals.getSystemStatusDescription(usageRow.title)}</h3>
                                            {Generals.getSystemStatusCorrectDisplay(usageRow)}
                                        </div>
                                    </div>
                                </div>
                            )
                }
            </div>
            <h2 className="text-2xl font-main-bold mr-3">Seteaza drepturi pentru utilizatorii:</h2>
            <div className={"mt-4"}>
                {
                    employeesLoading ?
                        <div role="status" className="max-w-sm animate-pulse">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <div className="flex flex-col w-full gap-4">
                            {employees.map((employee, index) => (
                                <div key={index}
                                     className="bg-gray-200 w-full p-4 rounded-md flex justify-between items-center">
                                    <div>
                                        <p className="text-lg font-main-medium">{employee.username}</p>
                                        <p className="text-sm text-gray-600">{employee.email}</p>
                                    </div>
                                    {
                                        employeesUsingAppLoading ?
                                            <Loading color={"fill-accent-main-500"}/> :
                                            employeesUsingApp.map((e) => e.slug).includes(employee.slug) ?
                                                <button type="button"
                                                        disabled={acceptanceEmployeeLoading}
                                                        className="text-white bg-error hover:bg-red-500 font-main-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                                                        onClick={() => disallowAppToUser(employee.slug)}>
                                                    {acceptanceEmployeeLoading ? <Loading/> : "Sterge acces"}
                                                </button>
                                                :
                                                <button type="button"
                                                        disabled={acceptanceEmployeeLoading}
                                                        className="text-white bg-accent-main-500 font-main-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                                                        onClick={() => allowAppToUser(employee.slug)}>
                                                    {acceptanceEmployeeLoading ? <Loading/> : "Acorda acces"}
                                                </button>
                                    }
                                </div>
                            ))}
                        </div>
                }
            </div>
        </>
    );
}