export enum DocumentTypeEnhanced {
    TRANSPORTATION_LICENCE = "LT",
    EXPEDITION_LICENCE = "LE",
    CMR_INSURANCE = "ACMR",
    ANEXES = "AN",
    PAYMENT_PROOF = "DP",
    EXPEDITION_INSURANCE = "AE",
    CI = "CI",
    TALON = "TL",
    CUI = "CUI",
    TRANSPORTATION_CONFORM_COPY = "CC",
    GENERAL = "GENERAL"
}
