import React, {useContext, useEffect, useState} from "react";
import {AnalysisTaskStages} from "../../../models/AnalyseTask";
import {MdEdit} from "react-icons/md";
import Loading from "../../../commons/component/loading/views/Loading";
import ProviderStatus from "../../../models/responses/ProviderStatus";
import UnlinkProviderRequest from "../../../models/requests/UnlinkProviderRequest";
import {toast} from "react-hot-toast";
import Generals from "../../../commons/component/generals/Generals";
import CreateProviderRequest from "../../../models/requests/CreateProviderRequest";
import RegisterService from "../../../services/RegisterService";
import {IoClose, IoCloud} from "react-icons/io5";
import OcrDetailsInterface from "../../../models/OcrDetailsInterface";
import Provider from "../../../models/Provider";
import AiDocumentCheckerService from "../../../services/AiDocumentCheckerService";
import ProvidersService from "../../../services/ProvidersService";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import AnalysisDetails from "../../../models/AnalysisDetails";

interface AiDocumentCheckerProviderComponentInterface {
    taskDetails: AnalysisDetails | undefined;
    taskStage: AnalysisTaskStages;
    taskSlug: string;
    providers: Provider[]
}

const AiDocumentCheckerProviderComponent: React.FC<AiDocumentCheckerProviderComponentInterface> = ({
                                                                                                       taskStage,
                                                                                                       taskDetails,
                                                                                                       taskSlug,
                                                                                                       providers
                                                                                                   }) => {
    const {user} = useContext(AuthContext);
    const [isLoadingAddingProvider, setIsLoadingAddingProvider] = useState<boolean>(false);
    const [isLoadingOcrDetailsSaving, setIsLoadingOcrDetailsSaving] = useState<boolean>(false);
    const [isLoadingExtractProviders, setIsLoadingExtractProvider] = useState<boolean>(false);
    const [isLoadingSelectingProvider, setIsLoadingSelectingProvider] = useState<boolean>(false);
    const [isLoadingProviderGetByCui, setIsLoadingProviderGetByCui] = useState(false);
    const [loadedCui, setLoadedCui] = useState(false);
    const [ocrArrayRequested, setOcrArrayRequested] = useState<OcrDetailsInterface[] | null>([]);
    const [ocrArrayPreviousState, setOcrArrayPreviousState] = useState<OcrDetailsInterface[] | null>([]);

    const [buildProviderStatus, setExtractedProviderStatus] = useState<ProviderStatus>(ProviderStatus.NONE);
    const [createProviderRequest, setCreateProviderRequest] = useState<CreateProviderRequest | null>(null);

    const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
    const [initialSelectedProvider, setInitialSelectedProvider] = useState<string | null>(null);

    const [openedProviderCreationModal, setOpenedProviderCreationModal] = useState<boolean>(false);
    const [editModeFields, setEditModeFields] = useState<boolean>(false);

    const service = new AiDocumentCheckerService();
    const providerService = new ProvidersService();

    useEffect(() => {
        console.log(taskDetails);
        if (taskDetails != null) {
            setOcrArrayRequested(taskDetails.details);
            setOcrArrayPreviousState(taskDetails.details);
            setInitialSelectedProvider(taskDetails.preselectedProvider);
            setSelectedProvider(taskDetails.preselectedProvider);
        }
    }, [taskDetails]);

    useEffect(() => {
        if (taskStage === AnalysisTaskStages.FINISHED && taskDetails?.preselectedProvider === null)
            extractProvider();
    }, [ocrArrayPreviousState]);

    useEffect(() => {
        console.log(initialSelectedProvider)
    }, [initialSelectedProvider]);

    const extractProvider = () => {
        setIsLoadingExtractProvider(true);
        providerService.extractProvider(taskSlug ?? "")
            .then((resp) => {
                if (resp.cuiDetails != null)
                    setCreateProviderRequest({
                        name: "",
                        cui: resp.cuiDetails,
                        address: "",
                        gei: "",
                        isVerified: false,
                        city: "",
                        country: "",
                        county: "",
                        taskSlug: taskSlug ?? "",
                        caenCode: ""
                    });
                setSelectedProvider(initialSelectedProvider == null ? resp.preselectedProvider : initialSelectedProvider);
                setExtractedProviderStatus(resp.status);
            })
            .catch((error) => toast.error(Generals.buildError(error)))
            .finally(() => setIsLoadingExtractProvider(false))
    }

    const selectNewProvider = () => {
        if (initialSelectedProvider === selectedProvider)
            return;

        let req: UnlinkProviderRequest = {
            newProviderIdentification: selectedProvider ?? "none",
            taskSlug: taskSlug ?? ""
        };

        setIsLoadingSelectingProvider(true);
        providerService.changeProvider(req)
            .then((_) => {
                toast.success("Furnizor schimbat cu success");
                setInitialSelectedProvider(selectedProvider);
            })
            .catch((error) => toast.error(Generals.buildError(error)))
            .finally(() => setIsLoadingSelectingProvider(false));
    }

    const renderTableData = () => {
        if (!taskDetails) return null;

        return (ocrArrayRequested ?? []).map((item: any, index: number) => {
            const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof typeof item) => {
                const newValue = e.target.value;
                setOcrArrayRequested((prevDetails) =>
                    prevDetails?.map((detail, i) =>
                        i === index ? {...detail, [field]: newValue} : detail
                    ) ?? []
                );
            };

            return (
                <div key={index} className={"border-b py-2 text-start flex mb-2 lg:mb-0 lg:gap-2 flex-col lg:flex-row"}>
                    <div className={"text-xl lg:text-base w-full lg:w-[200px]"}>
                        {item.type}
                    </div>
                    <div className={"w-full overflow-hidden text-ellipsis"}>
                        {editModeFields ? (
                            <input
                                type="text"
                                className={"p-2 outline-none w-full border rounded-lg"}
                                value={ocrArrayRequested?.[index]?.mentionText || ""}
                                onChange={(e) => handleInputChange(e, "mentionText")}
                            />
                        ) : (
                            item.mentionText
                        )}
                    </div>
                </div>
            );
        });
    };

    const addProvider = () => {
        console.log(createProviderRequest);
        if (user === null || createProviderRequest === null)
            return;

        if (Generals.checkIfProviderCreateHasEmptyFields(createProviderRequest)) {
            toast.error("Completeaza toate campurile")
            return;
        }

        setIsLoadingAddingProvider(true);
        providerService.createProvider(createProviderRequest).then((res) => {
            setCreateProviderRequest(null);
            setExtractedProviderStatus(ProviderStatus.NONE);
            setInitialSelectedProvider(selectedProvider);
            toast.success(res);
        })
            .catch((error) => toast.error(Generals.buildError(error)))
            .finally(() => setIsLoadingAddingProvider(false));
    }

    const saveOcrData = () => {
        if (ocrArrayRequested == null) return;
        if (ocrArrayRequested.some((el) => el.mentionText === "")) {
            toast.error("Unele campuri sunt goale!");
            return;
        }

        setIsLoadingOcrDetailsSaving(true);
        service.saveOcrFields(user!.firmSlug, taskSlug ?? "", ocrArrayRequested).then((_) => {
            setOcrArrayPreviousState(ocrArrayRequested);
            setEditModeFields(false);
        })
            .catch((error) => toast.error(Generals.buildError(error)))
            .finally(() => setIsLoadingOcrDetailsSaving(false));
    }

    const handleProviderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setCreateProviderRequest((prevData) => ({
            ...prevData,
            isVerified: false,
            [name]: value,
        } as CreateProviderRequest));
    };

    const handleProviderUiChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProvider(event.target.value);
        setExtractedProviderStatus(ProviderStatus.NONE);
    }

    const getCuiData = () => {
        setLoadedCui(false);
        setIsLoadingProviderGetByCui(true);

        new RegisterService().checkCui(createProviderRequest?.cui ?? "").then((response) => {
            setCreateProviderRequest({
                name: response.name,
                address: response.address,
                cui: response.cui,
                gei: response.jreg,
                isVerified: true,
                county: response.county,
                country: "Romania",
                city: response.city,
                caenCode: response.caenCode,
                taskSlug: taskSlug ?? ""
            });
        }).catch((error) => {
            setCreateProviderRequest({
                name: "",
                cui: createProviderRequest?.cui ?? "",
                address: "",
                gei: "",
                isVerified: false,
                city: "",
                country: "",
                county: "",
                caenCode: "",
                taskSlug: taskSlug ?? ""
            });
            toast.error(Generals.buildError(error))
        }).finally(() => {
            setLoadedCui(true);
            setIsLoadingProviderGetByCui(false);
        });
    }

    return (
        <>
            <div className="table-auto w-full mt-2 flex justify-between items-center">
                <div className="py-2 text-start w-[200px] font-bold text-lg">Camp</div>
                <div className={"flex flex-row w-full"}>
                    <div className="py-2 text-start w-full font-bold text-lg">Valoare</div>
                    <div className={"py-2 flex justify-end items-center"}>
                        {
                            editModeFields
                                ? <></>
                                : <button
                                    onClick={() => setEditModeFields(true)}
                                    className={"p-2 rounded-lg bg-gray-100"}>
                                    <MdEdit/>
                                </button>
                        }
                    </div>
                </div>
            </div>
            {renderTableData()}
            {
                !editModeFields
                    ? <></>
                    : <div
                        className={"px-2 py-2 mb-4 flex justify-center gap-5 items-center"}>
                        <button
                            onClick={() => {
                                setOcrArrayRequested(ocrArrayPreviousState);
                                setEditModeFields(false)
                            }}
                            className={"bg-accent-main-500 py-1 px-2 rounded-lg text-white"}>
                            Renunta
                        </button>
                        <button
                            disabled={isLoadingOcrDetailsSaving}
                            onClick={() => saveOcrData()}
                            className={"bg-accent-main-500 py-1 px-2 rounded-lg text-white"}>
                            {isLoadingOcrDetailsSaving ? <Loading/> : "Salveaza"}
                        </button>
                    </div>
            }
            {
                taskStage !== AnalysisTaskStages.FINISHED
                    ? <></>
                    : <div>
                        <div className={"font-main-medium mt-5"}>
                            Furnizorul sugerat:
                        </div>
                        <div className={"flex gap-2 flex-row"}>
                            <select
                                value={selectedProvider ?? 'default'}
                                onChange={handleProviderUiChange}
                                className="appearance-none w-full bg-white border border-accent-main-500 px-4 py-2 rounded-lg shadow-lg outline-none"
                            >
                                <option value="default" disabled>Selecteaza un furnizor
                                </option>
                                {(providers ?? []).map((provider) => (
                                    <option
                                        key={provider.cui}
                                        value={provider.cui || ''}
                                    >
                                        {provider.name}
                                    </option>
                                ))}
                            </select>
                            {
                                initialSelectedProvider === selectedProvider
                                    ? <></>
                                    : isLoadingExtractProviders
                                        ? <></>
                                        : <button
                                            disabled={isLoadingSelectingProvider}
                                            onClick={selectNewProvider}
                                            className={"bg-accent-main-500 p-2 rounded-lg shadow-lg w-1/2 text-white flex justify-center items-center font-main-medium"}>
                                            {
                                                isLoadingSelectingProvider ?
                                                    <Loading/> : "Salveaza"
                                            }
                                        </button>
                            }
                        </div>
                        {
                            isLoadingExtractProviders
                                ? <Loading color={"fill-accent-main-500"}/>
                                : buildProviderStatus === ProviderStatus.EXISTS || buildProviderStatus === ProviderStatus.NONE ?
                                    <button
                                        disabled={isLoadingAddingProvider}
                                        className={"mt-2 p-2 w-full bg-accent-main-500 rounded-lg text-white transition-all hover:bg-accent-main-600"}
                                        onClick={() => setOpenedProviderCreationModal(true)}>
                                        {
                                            isLoadingAddingProvider
                                                ? <Loading/>
                                                : "Creaza un furnizor"
                                        }
                                    </button> :
                                    <div
                                        className={"border border-accent-main-500 mt-2 rounded-lg shadow-lg bg-white p-2 flex justify-center items-center flex-col gap-2"}>
                                        <p className={"font-main-medium text-accent-main-500 text-center"}>
                                            {
                                                buildProviderStatus === ProviderStatus.NEW ?
                                                    <>
                                                        Am detectat un nou furnizor. <br/>
                                                        Doresti sa il salvezi?
                                                    </> :
                                                    <>
                                                        Acest furnizor nu are toate datele
                                                        complete. <br/>
                                                        Doresti sa le salvezi complet?
                                                    </>
                                            }
                                        </p>
                                        <button
                                            disabled={isLoadingAddingProvider}
                                            className={"bg-accent-main-500 rounded-lg text-white p-2 transition-all hover:bg-accent-main-600"}
                                            onClick={() => setOpenedProviderCreationModal(true)}>
                                            {
                                                isLoadingAddingProvider
                                                    ? <Loading/>
                                                    : buildProviderStatus === ProviderStatus.NEW
                                                        ? "Adauga furnizor"
                                                        : "Salveaza datele noi"
                                            }
                                        </button>
                                    </div>
                        }
                    </div>
            }
            {
                !openedProviderCreationModal
                    ? <></>
                    : <div
                        className={"w-screen h-screen fixed inset-0 p-10 z-40 bg-black bg-opacity-50 flex justify-center items-center"}>
                        <div className={"w-full md:w-1/3 h-fit rounded-lg bg-white p-5"}>
                            <div className={"flex justify-end items-center"}>
                                <button
                                    onClick={() => {
                                        if (buildProviderStatus !== ProviderStatus.NEW && buildProviderStatus !== ProviderStatus.UPDATE)
                                            setCreateProviderRequest(null);

                                        setOpenedProviderCreationModal(false)
                                    }}
                                    className={"bg-gray-300 p-2 rounded-full hover:bg-error hover:text-white text-gray-500"}>
                                    <IoClose/>
                                </button>
                            </div>
                            <h1 className={"text-xl pb-3 text-center"}>Adauga un nou furnizor</h1>
                            <div className={"flex gap-2 items-center"}>
                                <input type="text"
                                       name="cui"
                                       value={createProviderRequest?.cui ?? ""}
                                       placeholder={"CUI firmei"}
                                       onChange={handleProviderChange}
                                       className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                <div
                                    onClick={getCuiData}
                                    className={"flex gap-2 items-center cursor-pointer h-fit bg-accent-main-500 rounded-lg text-white py-2.5 px-4"}>
                                    <IoCloud/> Cauta
                                </div>
                            </div>
                            {
                                !loadedCui ?
                                    isLoadingProviderGetByCui ?
                                        <div className={"w-full flex justify-center py-2"}>
                                            <Loading color={"fill-accent-main-500"}/>
                                        </div> : <></>
                                    : <>
                                        <input type="text"
                                               name="name"
                                               placeholder={"Numele firmei"}
                                               value={createProviderRequest?.name ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <input type="text"
                                               name="address"
                                               placeholder={"Adresa firmei"}
                                               value={createProviderRequest?.address ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <input type="text"
                                               name="gei"
                                               placeholder={"J Reg al firmei"}
                                               value={createProviderRequest?.gei ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <input type="text"
                                               name="city"
                                               placeholder={"Oras"}
                                               value={createProviderRequest?.city ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <input type="text"
                                               name="county"
                                               placeholder={"Judet"}
                                               value={createProviderRequest?.county ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <input type="text"
                                               name="country"
                                               placeholder={"Tara"}
                                               value={createProviderRequest?.country ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <button
                                            disabled={isLoadingAddingProvider}
                                            className={"mt-2 p-2 flex justify-center items-center w-full bg-accent-main-500 rounded-lg text-white transition-all hover:bg-accent-main-600"}
                                            onClick={addProvider}
                                        >
                                            {
                                                isLoadingAddingProvider
                                                    ? <Loading/>
                                                    : "Adauga furnizor"
                                            }
                                        </button>
                                    </>
                            }
                        </div>
                    </div>
            }
        </>
    );
}

export default AiDocumentCheckerProviderComponent;