import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import Loading from "../../../commons/component/loading/views/Loading";
import Provider from "../../../models/Provider";
import {toast} from "react-hot-toast";
import ProvidersService from "../../../services/ProvidersService";
import ProviderRouter from "../../../routers/ProviderRouter";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import UserRoles from "../../../commons/component/header/functionals/models/UserRoles";
import {FiEdit} from "react-icons/fi";
import {FaRegTrashAlt} from "react-icons/fa";
import {HiOutlineSearch} from "react-icons/hi";
import {MdClear, MdOutlineCreateNewFolder} from "react-icons/md";
import {IoClose, IoCloud} from "react-icons/io5";
import RegisterService from "../../../services/RegisterService";
import Generals from "../../../commons/component/generals/Generals";
import CreateProviderRequest from "../../../models/requests/CreateProviderRequest";

export default function ProvidersDashboard() {

    const {user} = useContext(AuthContext);
    const [isLoadingProviders, setIsLoadingProviders] = useState<boolean>(false);
    const [isLoadingAddingProvider, setIsLoadingAddingProvider] = useState<boolean>(false);
    const [openedProviderCreationModal, setOpenedProviderCreationModal] = useState<boolean>(false);
    const [isLoadingProviderGetByCui, setIsLoadingProviderGetByCui] = useState(false);
    const [loadedCui, setLoadedCui] = useState(false);
    const [createProviderRequest, setCreateProviderRequest] = useState<CreateProviderRequest | null>(null);

    const [providers, setProviders] = useState<Provider[]>([]);
    const [displayProviders, setDisplayProviders] = useState<Provider[]>([]);
    const [searchedText, setSearchedText] = useState<string>("");
    const service = new ProvidersService();
    const location = useLocation();

    useEffect(() => {
        setIsLoadingProviders(true);
        service.getAllProviders()
            .then(response => {
                setProviders(response);
                setDisplayProviders(response);
            })
            .catch((_) => toast.error("Am intampiat o eroare. Incercati mai tarziu"))
            .finally(() => setIsLoadingProviders(false));
    }, []);

    useEffect(() => {
        if (searchedText.length === 0)
            setDisplayProviders(providers);

        setDisplayProviders(providers.filter(el =>
            el.cui.includes(searchedText) ||
            (el.relation ?? "").includes(searchedText) ||
            (el.name).toLowerCase().includes(searchedText.toLowerCase()))
        );
    }, [searchedText]);

    const deleteProvider = (item: Provider) => {
        setProviders(providers.filter(p => p.cui !== item.cui));
        service.deleteProvider(item.cui)
            .then((_) => {
                toast.success("Furnizor sters")
            })
            .catch((_) => {
                toast.error("Furnizorul nu s-a sters")
                setProviders([...providers, item]);
            })
    }

    const handleSearch = (searchTerm: string) => {
        setSearchedText(searchTerm);
    }

    const getCuiData = () => {
        setLoadedCui(false);
        setIsLoadingProviderGetByCui(true);

        new RegisterService().checkCui(createProviderRequest?.cui ?? "").then((response) => {
            setCreateProviderRequest({
                name: response.name,
                address: response.address,
                cui: response.cui,
                gei: response.jreg,
                isVerified: true,
                county: response.county,
                country: "Romania",
                city: response.city,
                caenCode: response.caenCode,
                taskSlug: ""
            });
        }).catch((error) => {
            setCreateProviderRequest({
                name: "",
                cui: createProviderRequest?.cui ?? "",
                address: "",
                gei: "",
                isVerified: false,
                city: "",
                country: "",
                caenCode: "",
                county: "",
                taskSlug: ""
            });
            toast.error(Generals.buildError(error))
        }).finally(() => {
            setLoadedCui(true);
            setIsLoadingProviderGetByCui(false);
        });
    }

    const handleProviderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setCreateProviderRequest((prevData) => ({
            ...prevData,
            isVerified: false,
            [name]: value || "",
        } as CreateProviderRequest));
    };

    const addProvider = () => {
        if (user === null || createProviderRequest === null)
            return;

        if (Generals.checkIfProviderCreateHasEmptyFields(createProviderRequest)) {
            toast.error("Completeaza toate campurile")
            return;
        }

        setIsLoadingAddingProvider(true);
        service.createProvider(createProviderRequest).then((res) => {
            setCreateProviderRequest(null);
            setOpenedProviderCreationModal(false);
            toast.success(res);
        })
            .catch((error) => toast.error(Generals.buildError(error)))
            .finally(() => setIsLoadingAddingProvider(false));
    }

    return (
        <>
            {
                isLoadingProviders ?
                    <div className={"flex justify-center gap-3 items-center"}>
                        <p>Se incarca furnizorii</p>
                        <Loading color={"fill-accent-main-500"}/>
                    </div> :
                    <div className={"flex flex-col gap-2"}>
                        <div className={"flex justify-end items-center gap-3"}>
                            <div
                                className="border group bg-white duration-300 focus-within:border-accent-main-500 w-full md:w-1/6 gap-2 rounded-xl flex justify-center items-center">
                                <HiOutlineSearch
                                    className="text-gray-300 duration-300 group-focus-within:text-accent-main-500 pl-2 w-[30px]"/>
                                <input
                                    value={searchedText}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    type="text"
                                    className="caret-accent-main-500 w-full outline-none p-2 rounded-full"
                                    placeholder="Cauta in furnizori"
                                />
                                {
                                    searchedText.length !== 0 && (
                                        <MdClear
                                            onClick={() => handleSearch("")}
                                            className="text-error pr-2 w-[30px]"
                                        />
                                    )
                                }
                            </div>
                            <button onClick={() => setOpenedProviderCreationModal(true)}
                                    className={"text-white p-2 px-4 bg-accent-main-500 gap-3 font-bold rounded-xl flex justify-center items-center flex-row"}>
                                <MdOutlineCreateNewFolder className={"w-[15px]"}/>
                                Creaza furnizor
                            </button>
                        </div>
                        <div className="overflow-x-auto rounded-lg">
                            <table className="min-w-full">
                                <thead>
                                <tr className="text-white text-start bg-accent-main-500">
                                    <th className="py-2 px-4 border-b">Actiuni</th>
                                    <th className="py-2 px-4 border-b">Denumire</th>
                                    <th className="py-2 px-4 border-b">CIF</th>
                                    <th className="py-2 px-4 border-b">Tara</th>
                                    <th className="py-2 px-4 border-b">Judet</th>
                                    <th className="py-2 px-4 border-b">Oras</th>
                                    <th className="py-2 px-4 border-b">Relatii</th>
                                    <th className="py-2 px-4 border-b">Observatii</th>
                                </tr>
                                </thead>
                                <tbody>
                                {displayProviders.map((item, index) => (
                                    <tr key={index}
                                        className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"} text-start border-b`}>
                                        <td className="py-2 px-4 gap-2 flex justify-center">
                                            <Link
                                                to={location.pathname + ProviderRouter.viewProviderDetailsEntry + item.cui}
                                                state={item}
                                                className="flex justify-center bg-green-500 items-center text-white p-2 rounded-full"
                                            >
                                                <FiEdit/>
                                            </Link>
                                            {
                                                (user?.role.name ?? UserRoles.ROLE_ACCOUNT_ADMIN) === UserRoles.ROLE_ACCOUNT_ADMIN
                                                    ?
                                                    <button
                                                        onClick={() => deleteProvider(item)}
                                                        className="flex justify-center items-center gap-1 bg-error text-white p-2 rounded-full"
                                                    >
                                                        <FaRegTrashAlt/>
                                                    </button>
                                                    : <></>
                                            }
                                        </td>
                                        <td className="py-2 px-4">{item.name}</td>
                                        <td className="py-2 px-4">{item.cui}</td>
                                        <td className="py-2 px-4">{item.country}</td>
                                        <td className="py-2 px-4">{item.county}</td>
                                        <td className="py-2 px-4">{item.city}</td>
                                        <td className="py-2 px-4">{item.relation ?? "-"}</td>
                                        <td className="py-2 px-4">{item.observations ?? "-"}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
            {
                !openedProviderCreationModal
                    ? <></>
                    : <div
                        className={"w-screen h-screen fixed inset-0 p-10 z-40 bg-black bg-opacity-50 flex justify-center items-center"}>
                        <div className={"w-full md:w-1/3 h-fit rounded-lg bg-white p-5"}>
                            <div className={"flex justify-end items-center"}>
                                <button
                                    onClick={() => {
                                        setCreateProviderRequest(null);
                                        setOpenedProviderCreationModal(false)
                                    }}
                                    className={"bg-gray-300 p-2 rounded-full hover:bg-error hover:text-white text-gray-500"}>
                                    <IoClose/>
                                </button>
                            </div>
                            <h1 className={"text-xl pb-3 text-center"}>Adauga un nou furnizor</h1>
                            <div className={"flex gap-2 items-center"}>
                                <input type="text"
                                       name="cui"
                                       value={createProviderRequest?.cui ?? ""}
                                       placeholder={"CUI firmei"}
                                       onChange={handleProviderChange}
                                       className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                <div
                                    onClick={getCuiData}
                                    className={"flex gap-2 items-center cursor-pointer h-fit bg-accent-main-500 rounded-lg text-white py-2.5 px-4"}>
                                    <IoCloud/> Cauta
                                </div>
                            </div>
                            {
                                !loadedCui ?
                                    isLoadingProviderGetByCui ?
                                        <div className={"w-full flex justify-center py-2"}>
                                            <Loading color={"fill-accent-main-500"}/>
                                        </div> : <></>
                                    : <>
                                        <input type="text"
                                               name="name"
                                               placeholder={"Numele firmei"}
                                               value={createProviderRequest?.name ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <input type="text"
                                               name="address"
                                               placeholder={"Adresa firmei"}
                                               value={createProviderRequest?.address ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <input type="text"
                                               name="gei"
                                               placeholder={"J Reg al firmei"}
                                               value={createProviderRequest?.gei ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <input type="text"
                                               name="city"
                                               placeholder={"Oras"}
                                               value={createProviderRequest?.city ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <input type="text"
                                               name="county"
                                               placeholder={"Judet"}
                                               value={createProviderRequest?.county ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <input type="text"
                                               name="country"
                                               placeholder={"Tara"}
                                               value={createProviderRequest?.country ?? ""}
                                               onChange={handleProviderChange}
                                               className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <button
                                            disabled={isLoadingAddingProvider}
                                            className={"mt-2 p-2 flex justify-center items-center w-full bg-accent-main-500 rounded-lg text-white transition-all hover:bg-accent-main-600"}
                                            onClick={addProvider}
                                        >
                                            {
                                                isLoadingAddingProvider
                                                    ? <Loading/>
                                                    : "Adauga furnizor"
                                            }
                                        </button>
                                    </>
                            }
                        </div>
                    </div>
            }
        </>
    );
}