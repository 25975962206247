import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {IoArrowForward, IoClose} from "react-icons/io5";
import AiDocumentCheckerRouter from "../../../routers/AiDocumentCheckerRouter";
import DashboardUserRouter from "../../../routers/DashboardUserRouter";
import {toast, Toaster} from "react-hot-toast";
import {PiIdentificationBadgeFill} from "react-icons/pi";
import {FaBookOpen} from "react-icons/fa";
import {FaLocationDot} from "react-icons/fa6";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";
import Utils from "../../../commons/functionals/utils/Utils";
import {MdClear, MdEdit, MdVerified} from "react-icons/md";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import ProvidersService from "../../../services/ProvidersService";
import CreateProviderRequest from "../../../models/requests/CreateProviderRequest";
import Loading from "../../../commons/component/loading/views/Loading";
import Generals from "../../../commons/component/generals/Generals";
import {HiOutlineSearch} from "react-icons/hi";
import ProviderContact from "../../../models/ProviderContact";
import {IoIosAdd} from "react-icons/io";
import SearchResult from "../../../models/responses/SearchResult";
import {LuExternalLink} from "react-icons/lu";
import ProviderComplete from "../../../models/ProviderComplete";

export default function ViewProviderDetails() {

    const {user} = useContext(AuthContext);
    const [editPopupOn, setEditPopupOn] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [providerDetails, setProviderDetails] = useState<ProviderComplete | null>(null);
    const [isLoadingAddingProvider, setIsLoadingAddingProvider] = useState<boolean>(false);
    const [createProviderRequest, setCreateProviderRequest] = useState<CreateProviderRequest | null>(null);

    const [addContactPopup, setAddContactPopup] = useState<boolean>(false);
    const [providerContactDetails, setProviderContactDetails] = useState<ProviderContact | null>(null);
    const [isLoadingProviderDetails, setIsLoadingProviderDetails] = useState<boolean>(false);

    const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [searchedText, setSearchedText] = useState<string>("");

    const [currentTab, setCurrentTab] = useState<number>(0);
    const service = new ProvidersService();
    const tabs = ["General", "Detalii furnizor", "Contact & nr. auto", "Documente atasate", "Smart Search"];
    const {providerCui} = useParams();

    useEffect(() => {
        if (providerCui === undefined)
            return;

        setIsLoading(true);
        service.getProviderDetails(providerCui ?? "")
            .then((response) => {
                setProviderDetails(response);
                initCreateProvider(response);
            })
            .catch((error) => toast.error(Generals.buildError(error)))
            .finally(() => setIsLoading(false));
    }, [providerCui]);

    useEffect(() => {
        console.log(providerDetails);
    }, [providerDetails]);

    const initCreateProvider = (firmProviderDetails: ProviderComplete) => {
        setCreateProviderRequest({
            name: firmProviderDetails.name,
            cui: firmProviderDetails.cui,
            address: firmProviderDetails.address,
            gei: firmProviderDetails.gei,
            city: firmProviderDetails.city,
            country: firmProviderDetails.country,
            county: firmProviderDetails.county,
            isVerified: firmProviderDetails.verified,
            caenCode: firmProviderDetails.caenCode,
            taskSlug: ""
        });
    }

    const handleProviderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setCreateProviderRequest((prevData) => ({
            ...prevData,
            [name]: value,
            isVerified: false,
        } as CreateProviderRequest));
    };

    const handleProviderContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setProviderContactDetails((prevData) => ({
            ...prevData,
            [name]: value,
        } as ProviderContact));
    };

    const updateProvider = () => {
        if (user === null)
            return;

        if (createProviderRequest === null)
            return;

        if (Generals.checkIfProviderCreateHasEmptyFields(createProviderRequest)) {
            toast.error("Completeaza toate campurile")
            return;
        }

        setIsLoadingAddingProvider(true);
        service.updateProvider(createProviderRequest.cui, createProviderRequest).then((res) => {
            setEditPopupOn(false);
            setProviderDetails((prev) => ({
                ...prev,
                name: createProviderRequest.name,
                address: createProviderRequest.address,
                gei: createProviderRequest.gei,
                city: createProviderRequest.city,
                country: createProviderRequest.country,
                county: createProviderRequest.county,
                verified: false
            }) as ProviderComplete);
            toast.success(res);
        })
            .catch((error) => {
                console.log(error);
                toast.error("Am intampinat o eroare!");
            })
            .finally(() => setIsLoadingAddingProvider(false));
    }

    // const debouncedSearch = () => Generals.debounce(search, 300);

    useEffect(() => {
        search();
    }, [searchedText]);

    const handleSearch = (searchTerm: string) => {
        setSearchedText(searchTerm);
        // debouncedSearch();
    }

    const search = () => {
        if (user === null)
            return;

        if (searchedText.length === 0)
            return;

        setIsLoadingSearch(true);
        service.search({searchQuery: searchedText, range: providerCui ?? null})
            .then((response) => setSearchResults(response))
            .catch((_) => {
            })
            .finally(() => setIsLoadingSearch(false));
    }

    const addContactProvider = () => {
        if (providerContactDetails === null)
            return;

        if (user === null)
            return;

        if (Generals.checkIfProviderContactCreateHasEmptyFields(providerContactDetails)) {
            toast.error("Completeaza toate campurile")
            return;
        }

        setIsLoadingProviderDetails(true);
        service.addContactToProvider(providerCui ?? "", providerContactDetails)
            .then((response) => {
                setProviderDetails((prev) => ({
                    ...prev,
                    contactList: [...providerDetails!.contactList, providerContactDetails]
                }) as ProviderComplete);
                setProviderContactDetails(null);
                setAddContactPopup(false);
                toast.success(response);
            })
            .catch((error) => toast.error(Generals.buildError(error)))
            .finally(() => setIsLoadingProviderDetails(false));
    }

    return (
        <>
            <div><Toaster/></div>
            {
                isLoading ?
                    <Loading color={"fill-accent-main-500"}/>
                    : <div className={"flex flex-col gap-3"}>
                        <div className={"rounded-xl bg-white p-4"}>
                            <h1 className={"font-main-medium text-5xl flex flex-row gap-2"}>
                                {
                                    providerDetails?.verified ?
                                        <MdVerified className={"text-green-500"}/>
                                        : <></>
                                }
                                {providerDetails?.name ?? "-"}
                            </h1>
                            <div className={"pt-3 flex flex-row gap-2"}>
                                {
                                    tabs.map((el, i) =>
                                        <div
                                            onClick={() => setCurrentTab(i)}
                                            className={`px-2.5 py-1 text-sm cursor-pointer border rounded-xl ${currentTab === i ? "border-accent-main-500" : "border-gray-100 bg-gray-100 text-gray-600 hover:text-black"}`}>
                                            {el}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {
                            currentTab === 0 || currentTab === 1
                                ? <div className={"rounded-xl bg-white p-4 group"}>
                                    <div className={"flex justify-between items-start"}>
                                        <div className={"w-full"}>
                                            <h1 className={"font-main-medium text-2xl pb-3"}>Detalii firma:</h1>
                                            <div className={"text-lg text-gray-600 flex flex-col"}>
                                                <div className={"flex flex-row"}>
                                                    <div
                                                        className={"flex flex-row gap-2 justify-start items-center w-[300px]"}>
                                                        <PiIdentificationBadgeFill/>
                                                        <p>CIF</p>
                                                    </div>
                                                    <p>{providerDetails?.cui ?? "-"}</p>
                                                </div>
                                                <div className={"flex flex-row"}>
                                                    <div
                                                        className={"flex flex-row gap-2 justify-start items-center w-[300px]"}>
                                                        <FaBookOpen/>
                                                        <p>Numar de inregistrare</p>
                                                    </div>
                                                    <p>{providerDetails?.gei ?? "-"}</p>
                                                </div>
                                                <div className={"flex flex-row"}>
                                                    <div
                                                        className={"flex flex-row gap-2 justify-start items-center w-[300px]"}>
                                                        <FaLocationDot/>
                                                        <p>Adresa</p>
                                                    </div>
                                                    <p>{providerDetails?.address ?? "-"}</p>
                                                </div>
                                                <div className={"flex flex-row"}>
                                                    <div
                                                        className={"flex flex-row gap-2 justify-start items-center w-[300px]"}>
                                                        <FaLocationDot/>
                                                        <p>Oras</p>
                                                    </div>
                                                    <p>{providerDetails?.city ?? "-"}</p>
                                                </div>
                                                <div className={"flex flex-row"}>
                                                    <div
                                                        className={"flex flex-row gap-2 justify-start items-center w-[300px]"}>
                                                        <FaLocationDot/>
                                                        <p>Cod caen</p>
                                                    </div>
                                                    <p>{providerDetails?.caenCode ?? "-"}</p>
                                                </div>
                                                <div className={"flex flex-row"}>
                                                    <div
                                                        className={"flex flex-row gap-2 justify-start items-center w-[300px]"}>
                                                        <FaLocationDot/>
                                                        <p>Judet</p>
                                                    </div>
                                                    <p>{providerDetails?.county ?? "-"}</p>
                                                </div>
                                                <div className={"flex flex-row"}>
                                                    <div
                                                        className={"flex flex-row gap-2 justify-start items-center w-[300px]"}>
                                                        <FaLocationDot/>
                                                        <p>Tara</p>
                                                    </div>
                                                    <p>{providerDetails?.country ?? "-"}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => setEditPopupOn(true)}
                                            className={"group-hover:block hidden p-2 rounded-lg bg-gray-100"}>
                                            <MdEdit/>
                                        </button>
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            currentTab === 0 || currentTab === 2
                                ? <div className={"rounded-xl bg-white p-4 group"}>
                                    <div className={"flex justify-between items-center"}>
                                        <h1 className={"font-main-medium text-2xl pb-3"}>Persoane de contact:</h1>
                                        <button
                                            onClick={() => setAddContactPopup(true)}
                                            className={"group-hover:block hidden p-2 rounded-lg bg-gray-100"}>
                                            <IoIosAdd/>
                                        </button>
                                    </div>
                                    <div className={"rounded-xl pb-5"}>
                                        {
                                            providerDetails?.contactList.length === 0
                                                ? <p className={"text-md text-gray-500"}>Nu ai introdus niciun contact</p>
                                                : <table className="min-w-full">
                                                    <thead>
                                                    <tr className="text-white bg-accent-main-500  border-b">
                                                        <th className="py-2 px-4">Nume</th>
                                                        <th className="py-2 px-4">Prenume</th>
                                                        <th className="py-2 px-4">Pozitie</th>
                                                        <th className="py-2 px-4">Email</th>
                                                        <th className="py-2 px-4">Nr telefon</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {providerDetails?.contactList.map((item, index) => (
                                                        <tr key={index} className="text-center bg-white border-b">
                                                            <td className="py-2 px-4">{item.lastName}</td>
                                                            <td className="py-2 px-4">{item.lastName}</td>
                                                            <td className="py-2 px-4">{item.jobPosition}</td>
                                                            <td className="py-2 px-4">
                                                                <a href={"mailTo:" + item.email}>{item.email}</a>
                                                            </td>
                                                            <td className="py-2 px-4">{item.mobilePhone}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                        }
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            currentTab === 0 || currentTab === 3
                                ? <div className={"rounded-xl bg-white p-4"}>
                                    <h1 className={"font-main-medium text-2xl pb-3"}>Documente atasate:</h1>
                                    {
                                        providerDetails?.documentsLinked.length === 0 ?
                                            <p className={"text-md text-gray-500"}>Nu ai atasat niciun document</p>
                                            : <table className="min-w-full rounded-xl">
                                                <thead>
                                                <tr className="text-white bg-accent-main-500 border-b">
                                                    <th className="py-2 px-4">Numele documentului</th>
                                                    <th className="py-2 px-4">Tipul documentului</th>
                                                    <th className="py-2 px-4">Vezi analiza documentului atasate</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {providerDetails?.documentsLinked.map((item, index) => (
                                                    <tr key={index} className="bg-white border-b text-center">
                                                        <td className="py-2 px-4">{item.documentName}</td>
                                                        <td className="py-2 px-4">{Utils.convertToText(item.documentType)}</td>
                                                        <td className="py-2 px-4 flex justify-center">
                                                            <Link
                                                                to={ApplicationRoutes.dashboard + DashboardUserRouter.AiDocumentChecker + AiDocumentCheckerRouter.viewTaskEntrance + providerDetails?.documentsLinked[index].taskSlug}
                                                                className="flex justify-center items-center gap-1 font-bold"
                                                            >
                                                                Catre analiza <IoArrowForward/>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                    }
                                </div>
                                : <></>
                        }
                        {
                            currentTab === 4
                                ? <div className={"rounded-xl bg-white p-4 flex flex-col justify-center items-center"}>
                                    <h1 className={"font-main-medium text-2xl pb-3 text-center"}>Smart Search</h1>
                                    <div
                                        className="border group bg-white duration-300 focus-within:border-accent-main-500 w-full md:w-2/3 gap-2 rounded-xl flex justify-center items-center">
                                        <HiOutlineSearch
                                            className="text-gray-300 duration-300 group-focus-within:text-accent-main-500 pl-2 w-[30px]"/>
                                        <input
                                            value={searchedText}
                                            onChange={(e) => handleSearch(e.target.value)}
                                            type="text"
                                            className="caret-accent-main-500 w-full outline-none p-2 rounded-full"
                                            placeholder="Cauta aici orice detaliu"
                                        />
                                        {
                                            searchedText.length !== 0 && (
                                                <MdClear
                                                    onClick={() => handleSearch("")}
                                                    className="text-error pr-2 w-[30px]"
                                                />
                                            )
                                        }
                                    </div>
                                    <div className={"w-full pt-3"}>
                                        {
                                            isLoadingSearch ?
                                                <Loading color={"fill-accent-main-500"}/>
                                                : searchResults.map((result, i) =>
                                                    <div key={i} className={"w-full py-3 px-2"}>
                                                        <Link
                                                            to={ApplicationRoutes.dashboard + DashboardUserRouter.AiDocumentChecker + AiDocumentCheckerRouter.viewTaskEntrance + result.taskSlug}
                                                        >
                                                            <p className={"text-lg flex flex-row gap-2 items-center"}>
                                                                Document: {result.documentName}
                                                                <LuExternalLink/>
                                                            </p>
                                                        </Link>
                                                        <p className={"text-sm"}>Tip: {Utils.convertToText(result.documentType)}</p>
                                                        <p className={"text-sm text-gray"}>...{result.resultText}...</p>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                                : <></>
                        }
                    </div>
            }
            {
                !editPopupOn
                    ? <></>
                    : <div
                        className={"w-screen h-screen fixed inset-0 p-10 z-40 bg-black bg-opacity-50 flex justify-center items-center"}>
                        <div className={"w-full md:w-1/3 h-fit rounded-lg bg-white p-5"}>
                            <div className={"flex justify-end items-center"}>
                                <button
                                    onClick={() => {
                                        initCreateProvider(providerDetails!);
                                        setEditPopupOn(false)
                                    }}
                                    className={"bg-gray-300 p-2 rounded-full hover:bg-error hover:text-white text-gray-500"}>
                                    <IoClose/>
                                </button>
                            </div>
                            <h1 className={"text-xl pb-3 text-center"}>
                                Editeaza detaliile furnizorului <br/>
                                {providerDetails?.name ?? ""}
                            </h1>
                            <input type="text"
                                   name="name"
                                   placeholder={"Numele firmei"}
                                   value={createProviderRequest?.name ?? ""}
                                   onChange={handleProviderChange}
                                   className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                            <input type="text"
                                   name="address"
                                   placeholder={"Adresa firmei"}
                                   value={createProviderRequest?.address ?? ""}
                                   onChange={handleProviderChange}
                                   className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                            <input type="text"
                                   name="gei"
                                   placeholder={"J Reg al firmei"}
                                   value={createProviderRequest?.gei ?? ""}
                                   onChange={handleProviderChange}
                                   className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                            <input type="text"
                                   name="city"
                                   placeholder={"Oras"}
                                   value={createProviderRequest?.city ?? ""}
                                   onChange={handleProviderChange}
                                   className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                            <input type="text"
                                   name="county"
                                   placeholder={"Judet"}
                                   value={createProviderRequest?.county ?? ""}
                                   onChange={handleProviderChange}
                                   className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                            <input type="text"
                                   name="country"
                                   placeholder={"Tara"}
                                   value={createProviderRequest?.country ?? ""}
                                   onChange={handleProviderChange}
                                   className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                            <button
                                disabled={isLoadingAddingProvider}
                                className={"mt-2 p-2 flex justify-center items-center w-full bg-accent-main-500 rounded-lg text-white transition-all hover:bg-accent-main-600"}
                                onClick={updateProvider}
                            >
                                {
                                    isLoadingAddingProvider
                                        ? <Loading/>
                                        : "Salveaza datele noi"
                                }
                            </button>
                        </div>
                    </div>
            }
            {
                !addContactPopup
                    ? <></>
                    : <div
                        className={"w-screen h-screen fixed inset-0 p-10 z-40 bg-black bg-opacity-50 flex justify-center items-center"}>
                        <div className={"w-full md:w-1/3 h-fit rounded-lg bg-white p-5"}>
                            <div className={"flex justify-end items-center"}>
                                <button
                                    onClick={() => {
                                        setProviderContactDetails(null);
                                        setAddContactPopup(false)
                                    }}
                                    className={"bg-gray-300 p-2 rounded-full hover:bg-error hover:text-white text-gray-500"}>
                                    <IoClose/>
                                </button>
                            </div>
                            <h1 className={"text-xl pb-3 text-center"}>
                                Adauga un nou contact
                            </h1>
                            <input type="text"
                                   name="firstName"
                                   placeholder={"Nume"}
                                   value={providerContactDetails?.firstName ?? ""}
                                   onChange={handleProviderContactChange}
                                   className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                            <input type="text"
                                   name="lastName"
                                   placeholder={"Prenume"}
                                   value={providerContactDetails?.lastName ?? ""}
                                   onChange={handleProviderContactChange}
                                   className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                            <input type="text"
                                   name="email"
                                   placeholder={"Email"}
                                   value={providerContactDetails?.email ?? ""}
                                   onChange={handleProviderContactChange}
                                   className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                            <input type="text"
                                   name="jobPosition"
                                   placeholder={"Pozitie"}
                                   value={providerContactDetails?.jobPosition ?? ""}
                                   onChange={handleProviderContactChange}
                                   className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                            <input type="text"
                                   name="mobilePhone"
                                   placeholder={"Nr. telefon"}
                                   value={providerContactDetails?.mobilePhone ?? ""}
                                   onChange={handleProviderContactChange}
                                   className="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                            <button
                                disabled={isLoadingProviderDetails}
                                className={"mt-2 p-2 flex justify-center items-center w-full bg-accent-main-500 rounded-lg text-white transition-all hover:bg-accent-main-600"}
                                onClick={addContactProvider}
                            >
                                {
                                    isLoadingProviderDetails
                                        ? <Loading/>
                                        : "Adauga contact"
                                }
                            </button>
                        </div>
                    </div>
            }
        </>
    );
}