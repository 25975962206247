import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "../../commons/component/header/views/Header";
import {AuthProvider} from "../../commons/functionals/authLogic/AuthContext";
import Home from "../home/Home";
import LoginPage from "../login/LoginPage";
import RegisterPage from "../register/RegisterPage";
import VerifyTokenPage from "../verify-token/VerifyTokenPage";
import DashboardRootPage from "../dashboard/DashboardRootPage";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import Error404 from "../error404/Error404";
import DashboardAdminPageRoot from "../dashboard_admin/views/DashboardAdminPageRoot";
import DashboardAdminRouter from "../../routers/DashboardAdminRouter";
import PrivateAccessRoute from "../../commons/functionals/authLogic/route_permitters/PrivateAccessRoute";
import DashboardUserRouter from "../../routers/DashboardUserRouter";
import UserRoles from "../../commons/component/header/functionals/models/UserRoles";

function App() {
    return (
        <div className={"font-main-light"}>
            <AuthProvider>
                <BrowserRouter>
                    <Header/>
                    <div>
                        <Routes>
                            <Route path={ApplicationRoutes.root} element={<Home/>}/>
                            <Route path={ApplicationRoutes.login} element={<LoginPage/>}/>
                            <Route path={ApplicationRoutes.register} element={<RegisterPage/>}/>
                            <Route path={ApplicationRoutes.verify} element={<VerifyTokenPage/>}/>
                            {
                                // === PRIVATE ROUTES ===
                            }
                            <Route path={ApplicationRoutes.dashboard + DashboardUserRouter.entrance}
                                   element={
                                       <PrivateAccessRoute
                                           permittedApplicationAccess={"*"}
                                           permittedRole={[UserRoles.ROLE_EMPLOYEE, UserRoles.ROLE_ACCOUNT_ADMIN]}>
                                           <DashboardRootPage/>
                                       </PrivateAccessRoute>
                                   }/>
                            {
                                // === ADMIN ROUTES ===
                            }
                            <Route path={ApplicationRoutes.dashboardAdminRoot + DashboardAdminRouter.entrance}
                                   element={
                                       <PrivateAccessRoute permittedApplicationAccess={"*"}
                                                           permittedRole={[UserRoles.ROLE_SUPER_ADMIN]}>
                                           <DashboardAdminPageRoot/>
                                       </PrivateAccessRoute>
                                   }/>
                            <Route path={ApplicationRoutes.any} element={<Error404/>}/>
                        </Routes>
                    </div>
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
}

export default App;
