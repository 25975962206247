import React, {useContext, useEffect, useState} from "react";
import AiDocumentCheckerService from "../../../services/AiDocumentCheckerService";
import {AnalyseTask} from "../../../models/AnalyseTask";
import {IoCloudUploadOutline} from "react-icons/io5";
import {toast, Toaster} from "react-hot-toast";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AiDocumentCheckerRouter from "../../../routers/AiDocumentCheckerRouter";
import Utils from "../../../commons/functionals/utils/Utils";
import Loading from "../../../commons/component/loading/views/Loading";
import {HiOutlineChevronLeft, HiOutlineChevronRight} from "react-icons/hi2";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import Generals from "../../../commons/component/generals/Generals";

export default function AiDocumentCheckerDashboard() {

    const {user} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingHistory, setIsLoadingHistory] = useState<boolean>(true);
    const [file, setFile] = useState<File | null>(null);
    const [previousTasks, setPreviousTasks] = useState<AnalyseTask[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [maxPage, setMaxPage] = useState<number>(0);
    const [isDragging, setIsDragging] = useState(false);
    const service = new AiDocumentCheckerService();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        getTasksForPage();
    }, [currentPage]);

    const getTasksForPage = () => {
        setIsLoadingHistory(true);
        service.getAllTasks(user!.firmSlug, currentPage)
            .then(response => {
                setPreviousTasks(response.content);
                setMaxPage(response.totalPages);
            })
            .catch((error) => toast.error(Generals.buildError(error)))
            .finally(() => setIsLoadingHistory(false));
    }

    const computeStyles = () => {
        return `flex flex-col items-center justify-center w-full h-64 border-2 border-${file == null ? "gray" : "accent-main"}-300 border-dashed rounded-lg cursor-pointer 
            bg-${file == null ? "gray" : "accent-main"}-50 
            hover:bg-${file == null ? "gray" : "accent-main"}-200 
            ${isDragging ? "border-blue-500 bg-blue-50" : ""}`;
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(false);
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            setFile(droppedFile);
        }
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) setFile(selectedFile);
    };

    const createTask = () => {
        if (file == null) {
            toast.error("Incarca un fisier ce trebuie verificat!");
            return;
        }

        setIsLoading(true);
        service.createTask(user!.firmSlug, file)
            .then((response) => {
                navigate(location.pathname + AiDocumentCheckerRouter.viewTaskEntrance + response);
            })
            .catch((error) => toast.error(Generals.buildError(error)))
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            <div><Toaster/></div>
            <div className={"flex justify-end items-center w-full mt-3 gap-3"}>
                <Link
                    to={location.pathname + AiDocumentCheckerRouter.blackListSearch}
                    className={"p-2 rounded-lg bg-accent-main-500 text-white"}>
                    BlackList Search
                </Link>
                <Link
                    to={location.pathname + AiDocumentCheckerRouter.education}
                    className={"p-2 rounded-lg bg-accent-main-500 text-white"}>
                    Procedura educatie
                </Link>
            </div>
            <div className="flex items-center justify-center w-full mt-3">
                <label
                    htmlFor="dropzone-file"
                    className={computeStyles()}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {file == null ? (
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <IoCloudUploadOutline className="w-10 h-10 text-gray-500"/>
                            <p className="font-main-medium mb-2 text-sm text-gray-500">
                                Click sau drag & drop pentru a incarca
                            </p>
                            <p className="text-xs text-gray-500">PDF, PNG, JPEG, JPG</p>
                        </div>
                    ) : (
                        <>
                            <img
                                src={URL.createObjectURL(file)}
                                className="w-52 h-32 rounded-lg object-cover"
                                alt=""
                            />
                            <p className="font-main-medium text-sm text-accent-main-500">{file.name}</p>
                            <p className="text-xs text-accent-main-500">{file.type}</p>
                        </>
                    )}
                    <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        accept="image/*,application/pdf"
                        onChange={handleFileChange}
                    />
                </label>
            </div>
            <div className={"w-full flex justify-center mt-3"}>
                <button
                    disabled={isLoading}
                    onClick={createTask}
                    className={"transition-all mb-3 w-fit p-2.5 flex gap-2 justify-center items-center text-center font-main-medium rounded-lg text-sm bg-accent-main-500 text-white"}>
                    {
                        isLoading ? <Loading color={"fill-white"}/> : "Trimite fisier spre analiza"
                    }
                </button>
            </div>
            <div className={"flex justify-end mr-2 mb-2"}>
                <button
                    className={"rounded-l-lg py-1 flex gap-2 items-center px-2 hover:bg-accent-main-500 hover:text-white border-r border-accent-main-500"}
                    onClick={() => setCurrentPage(currentPage - 1 < 0 ? 0 : (currentPage - 1))}>
                    <HiOutlineChevronLeft/> Inapoi
                </button>
                <button
                    className={"rounded-r-lg py-1 flex gap-2 items-center px-2 hover:bg-accent-main-500 hover:text-white"}
                    onClick={() => setCurrentPage(currentPage >= maxPage ? maxPage : (currentPage + 1))}>
                    Inainte <HiOutlineChevronRight/>
                </button>
            </div>
            {
                isLoadingHistory ?
                    <div className={"flex justify-center gap-2 items-center"}>
                        <p>Se incarca istoricul</p>
                        <Loading color={"fill-accent-main-500"}/>
                    </div> :
                    previousTasks.map((t, index) => <div
                        key={index}
                        className={"flex justify-between items-center w-full p-4 rounded-lg shadow-md bg-white mb-3"}>
                        <Link to={location.pathname + AiDocumentCheckerRouter.viewTaskEntrance + t.slug}>
                            <u>Document cu id: {t.slug}</u>
                        </Link>
                        <p>{Utils.convertToText(t.analysisDetails.documentType)}</p>
                        <p>
                            {new Date(t.startedAt).toLocaleDateString('en-GB')}{" "}
                            {new Date(t.startedAt).toLocaleTimeString('en-GB', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            })}
                        </p>
                    </div>)
            }
        </>
    );
}