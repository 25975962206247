import Loading from "../../../commons/component/loading/views/Loading";
import React, {useContext, useEffect, useState} from "react";
import SubscriptionUsage from "../../../models/SubscriptionUsage";
import FirmService from "../../../services/FirmService";
import Application from "../../../models/Application";
import Pair from "../../../models/Pair";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import Subscription from "../../../models/Subscription";
import Generals from "../../../commons/component/generals/Generals";

export default function FirmSubscriptionPage() {

    const {user} = useContext(AuthContext);

    const [usage, setUsage] = useState<Pair<string, SubscriptionUsage[]>[]>([]);
    const [usagesLoading, setUsagesLoading] = useState<boolean>(false);

    const [applications, setApplications] = useState<Application[]>([]);
    const [appsLoading, setAppsLoading] = useState<boolean>(false);

    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [subscriptionsLoading, setSubscriptionsLoading] = useState<boolean>(false);

    const service = new FirmService();

    useEffect(() => {
        if (user === null)
            return;

        setAppsLoading(true);
        setSubscriptionsLoading(true);
        setUsagesLoading(true);

        service.getMyApps()
            .then(response => setApplications(response))
            .catch((_) => console.log())
            .finally(() => setAppsLoading(false));

        service.getFirmUsages(user.firmSlug)
            .then(response => setUsage(response))
            .catch((_) => console.log())
            .finally(() => setUsagesLoading(false));

        service.getFirmSubscriptions(user.firmSlug)
            .then(response => setSubscriptions(response))
            .catch((_) => console.log())
            .finally(() => setSubscriptionsLoading(false));
    }, [user]);

    return (
        <>
            <div className={"pb-10 mt-10"}>
                <div className={"flex mb-4 justify-between"}>
                    <h2 className="text-2xl font-main-bold mr-3">Subscription Usages</h2>
                </div>
                <div className="">
                    {
                        usagesLoading ?
                            <Loading color={"fill-accent-main-500"}/> :
                            usage.length === 0 ?
                                <p>Nu s-au inregistrat nicio statistica</p> :
                                usage.map(usageRow =>
                                    <div className={"mb-3"}>
                                        <p className={"text-lg font-main-medium"}>{usageRow.first}</p>
                                        <div
                                            className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"}>
                                            {
                                                usageRow.second.map(uv =>
                                                    <div className="bg-white p-4 rounded-md shadow-md">
                                                        <h3 className="text-lg font-main-medium mb-2">{Generals.getSystemStatusDescription(uv.title)}</h3>
                                                        {Generals.getSystemStatusCorrectDisplay(uv)}
                                                    </div>)
                                            }
                                        </div>
                                    </div>
                                )
                    }
                </div>

                <h2 className="text-2xl font-main-bold mt-10 mb-4">Ai access la urmatoarele aplicatii</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {
                        appsLoading ?
                            <Loading color={"fill-accent-main-500"}/> :
                            applications.length === 0 ?
                                <p>Nu ai access la nicio aplicatie. Cumpara un abonament</p>
                                :
                                applications.map(
                                    (v, index) =>
                                        <div key={index}
                                             className={`p-4 rounded-md shadow-md flex`}
                                             style={{backgroundColor: v.headerColor}}>
                                            <h3 className="text-lg font-main-medium text-white w-full text-center">{v.name}</h3>
                                        </div>
                                )
                    }
                </div>
            </div>
        </>
    );
}