import React, {FormEvent, useEffect, useState} from "react";
import RegisterService from "../../services/RegisterService";
import Loading from "../../commons/component/loading/views/Loading";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import {IoIosArrowRoundForward} from "react-icons/io";
import {Link} from "react-router-dom";
import {IoArrowBack, IoArrowForward, IoCloud} from "react-icons/io5";
import {toast, Toaster} from "react-hot-toast";
import {FaEye, FaEyeSlash} from "react-icons/fa6";
import UserRequest from "../../models/requests/UserRequest";
import FirmRequest from "../../models/requests/FirmRequest";

export default function RegisterPage() {

    const defaultFirm: FirmRequest = {
        firmName: '',
        cui: '',
        jReg: '',
        city: '',
        county: '',
        firmAddress: '',
        vatPayer: '',
        tipFirma: '',
    };

    const [userDetails, setUserDetails] = useState<UserRequest>({
        email: '',
        password: '',
        username: '',
        phoneNumber: '',
    });
    const [firmDetails, setFirmDetails] = useState<FirmRequest>(defaultFirm);

    const [error, setError] = useState<boolean | null>(null);

    const [componentLoading, setComponentLoading] = useState(false);
    const [componentFirmLoading, setComponentFirmLoading] = useState(false);

    const [typedCui, setTypedCui] = useState<string>("");
    const [loadedCui, setLoadedCui] = useState(false);

    const [page, setPage] = useState<number>(1);
    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);

    const [firmType, setFirmType] = useState<string[]>([]);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);


    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleCheckboxChange = () => {
        setTermsAndConditionsAccepted(!termsAndConditionsAccepted);
    };


    const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setUserDetails((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFirmInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFirmDetails((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const areFieldsEmpty = (request: UserRequest | FirmRequest): boolean => {
        console.log(request);
        return Object.values(request).some(value => value.trim() === '');
    };

    const register = (event: FormEvent) => {
        event.preventDefault();
        userDetails.username = firstName + " " + lastName;

        if (areFieldsEmpty(userDetails)) {
            toast.error("Completeaza toate campurile de la fila utilizator!");
            return;
        }

        if (passwordConfirm !== userDetails.password) {
            toast.error("Confirmarea parolei este diferita!");
            return;
        }

        if (!/^J\d{2}\/\d+\/\d{4}$/.test(firmDetails.jReg)) {
            toast.error("Numarul de inregistrare nu este conform");
            return;
        }

        if (!testCuiValidity()) {
            toast.error("CUI-ul nu este conform");
            return;
        }

        setComponentLoading(true);
        new RegisterService().register({user: userDetails, firm: firmDetails}).then((_) => {
            setError(false);
        }).catch((_) => {
            setError(true);
            toast.error("Am intalnit o eroare. Incearca din nou, putin mai tarziu!")
        }).finally(() => setComponentLoading(false));
    }

    const getCuiData = () => {
        setLoadedCui(false);
        setComponentFirmLoading(true);

        new RegisterService().checkCui(typedCui).then((response) => {
            setFirmDetails({
                firmName: response.name,
                firmAddress: response.address,
                cui: response.cui,
                jReg: response.jreg,
                city: response.city,
                county: response.county,
                tipFirma: '',
                vatPayer: '1',
            });
            setLoadedCui(true);
        }).catch((_) => {
            setLoadedCui(false);
            setFirmDetails(defaultFirm);
            toast.error("Am intalnit o eroare. Incearca din nou!")
        }).finally(() => setComponentFirmLoading(false));
    }

    const testCuiValidity = () => {
        return /^\d{0,10}$/.test(typedCui);
    }

    const buttonState = () => {
        switch (page) {
            case 1:
                return <>Catre detalii cont <IoArrowForward/></>;
            case 2:
                return <><IoArrowBack/> Inapoi la detalii firma</>;
            default:
                return <></>
        }
    }

    const handleFirmTypeUpdate = (firmTypeSlug: string) => {
        let arr: string[] = [];
        switch (firmTypeSlug) {
            case 'expedition':
                arr = firmType.includes('expedition') ? [] : ['expedition'];
                if (firmType.includes('transportator')) arr.push('transportator');
                break;
            case 'transport':
                arr = firmType.includes('transport') ? [] : ['transport'];
                break;
            case 'transportator':
                arr = firmType.includes('transportator') ? [] : ['transportator'];
                if (firmType.includes('expedition')) arr.push('expedition');
                break;
        }
        setFirmType(arr);
    }

    useEffect(() => {
        setFirmDetails(prev => ({
            ...prev,
            tipFirma: firmType.join("|"),
        }));
    }, [firmType]);

    return (
        <div className={"h-screen w-screen flex justify-center items-center"}>
            <div><Toaster/></div>
            {
                error === false ?
                    <div className={"text-3xl text-center text-green-700"}>
                        <p>Inregistrare cu success</p>
                        <p>Te rugam sa accesezi linkul din emailul trimis pentru confirma contul!</p>
                    </div> :
                    <div className={"bg-white rounded-xl w-full md:w-2/3 lg:w-1/3 p-10"}>
                        <div className="mb-6">
                            <form onSubmit={register} className={"flex justify-center items-align flex-col"}>
                                {
                                    page === 1 && (<>
                                        <h1 className={"pb-4 text-3xl text-center font-main-bold"}>
                                            Cateva detalii despre firma ta:
                                        </h1>
                                        <div className={"flex gap-2 items-end"}>
                                            <div className={"w-full"}>
                                                <label className={"mt-3 text-sm"}>Introdu CUI-ul firmei tale</label>
                                                <input type="text"
                                                       required={true}
                                                       pattern="^[0-9]{2,10}$"
                                                       name="cui"
                                                       onChange={(e) => {
                                                           if (testCuiValidity()) {
                                                               setTypedCui(e.target.value);
                                                               setFirmDetails(defaultFirm);
                                                               setLoadedCui(false);
                                                           }
                                                       }}
                                                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                            </div>
                                            <div
                                                onClick={getCuiData}
                                                className={"flex gap-2 items-center cursor-pointer h-fit bg-accent-main-500 rounded-lg text-white py-2.5 px-4"}>
                                                <IoCloud/> Cauta
                                            </div>
                                        </div>
                                        {
                                            !loadedCui ?
                                                componentFirmLoading ?
                                                    <div className={"w-full flex justify-center py-2"}>
                                                        <Loading color={"fill-accent-main-500"}/>
                                                    </div> : <></>
                                                : <>
                                                    <label className={"mt-3 text-sm"}>Tipul Firmei</label>
                                                    <div className={"flex flex-col md:flex-row pt-2 gap-2"}>
                                                        <div
                                                            onClick={() => handleFirmTypeUpdate("expedition")}
                                                            className={`cursor-pointer font-semibold flex justify-center items-center text-center w-full p-4 rounded-lg ${firmType.includes("expedition") ? "bg-accent-main-500 text-white" : "bg-gray-50"}`}>
                                                            Companie
                                                            expeditie
                                                        </div>
                                                        <div
                                                            onClick={() => handleFirmTypeUpdate("transport")}
                                                            className={`cursor-pointer font-semibold flex justify-center items-center text-center w-full p-4 rounded-lg ${firmType.includes("transport") ? "bg-accent-main-500 text-white" : "bg-gray-50"}`}>
                                                            Client
                                                            transport
                                                        </div>
                                                        <div
                                                            onClick={() => handleFirmTypeUpdate("transportator")}
                                                            className={`cursor-pointer font-semibold flex justify-center items-center text-center w-full p-4 rounded-lg ${firmType.includes("transportator") ? "bg-accent-main-500 text-white" : "bg-gray-50"}`}>
                                                            Transportator
                                                        </div>
                                                    </div>
                                                    <label className={"mt-3 text-sm"}>Denumirea Firmei</label>
                                                    <input type="text"
                                                           required={true}
                                                           name="firmName"
                                                           placeholder={"Completeaza cu SRL la final"}
                                                           value={firmDetails.firmName}
                                                           onChange={handleFirmInputChange}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                                    <label className={"mt-3 text-sm"}>Reg Comert</label>
                                                    <input type="text"
                                                           required={true}
                                                           name="jReg"
                                                           value={firmDetails.jReg}
                                                           onChange={handleFirmInputChange}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                                    <label className={"mt-3 text-sm"}>Numele Sectorului/Orasului</label>
                                                    <input type="text"
                                                           required={true}
                                                           name="city"
                                                           value={firmDetails.city}
                                                           onChange={handleFirmInputChange}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                                    <label className={"mt-3 text-sm"}>Judet</label>
                                                    <input type="text"
                                                           required={true}
                                                           name="county"
                                                           value={firmDetails.county}
                                                           onChange={handleFirmInputChange}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                                    <label className={"mt-3 text-sm"}>Adresa</label>
                                                    <input type="text"
                                                           required={true}
                                                           name="firmAddress"
                                                           value={firmDetails.firmAddress}
                                                           onChange={handleFirmInputChange}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                                </>
                                        }
                                    </>)
                                }
                                {
                                    page === 2 && (
                                        <>
                                            <h1 className={"pb-4 text-3xl text-center font-main-bold"}>
                                                Contul este gata, spune-ne despre tine
                                            </h1>
                                            <div className={"flex flex-row gap-2"}>
                                                <div className={"w-full"}>
                                                    <label className={"mt-3 text-sm"}>Prenume</label>
                                                    <input type="text"
                                                           required={true}
                                                           value={firstName}
                                                           onChange={(e) => setFirstName(e.target.value)}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>

                                                </div>
                                                <div className={"w-full"}>
                                                    <label className={"mt-3 text-sm"}>Nume</label>
                                                    <input type="text"
                                                           required={true}
                                                           value={lastName}
                                                           onChange={(e) => setLastName(e.target.value)}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>

                                                </div>
                                            </div>
                                            <label className={"mt-3 text-sm"}>Email</label>
                                            <input type="text"
                                                   required={true}
                                                   name="email"
                                                   value={userDetails.email}
                                                   onChange={handleUserInputChange}
                                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                            <div className="mt-3">
                                                <label className={"mt-3 text-sm"}>Parola</label>
                                                <div className="relative mb-1">
                                                    <input
                                                        type={isPasswordVisible ? 'text' : 'password'}
                                                        required={true}
                                                        name="password"
                                                        value={userDetails.password}
                                                        onChange={handleUserInputChange}
                                                        className="mb-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                                    <button
                                                        type="button"
                                                        onClick={togglePasswordVisibility}
                                                        className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"
                                                    >
                                                        {isPasswordVisible ? <FaEyeSlash/> : <FaEye/>}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <label className="text-sm">Confirma parola</label>
                                                <div className="relative mb-1">
                                                    <input
                                                        type={isPasswordVisible ? 'text' : 'password'}
                                                        required={true}
                                                        value={passwordConfirm}
                                                        onChange={(e) => setPasswordConfirm(e.target.value)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500 pr-10"
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={togglePasswordVisibility}
                                                        className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"
                                                    >
                                                        {isPasswordVisible ? <FaEyeSlash/> : <FaEye/>}
                                                    </button>
                                                </div>
                                            </div>
                                            <label className={"mt-3 text-sm"}>Numar de telefon</label>
                                            <input type="text"
                                                   required={true}
                                                   name="phoneNumber"
                                                   value={userDetails.phoneNumber}
                                                   onChange={handleUserInputChange}
                                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                            <div className="mt-5">
                                                <label className="inline-flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-checkbox h-5 w-5 text-accent-main-500"
                                                        checked={termsAndConditionsAccepted}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <span className="ml-2 text-gray-700">
                                                        Am luat la cunoștință de
                                                        <Link to="https://inovia-trans.ro/terms-and-conditions"
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                              className="text-accent-main-500 hover:text-accent-main-800 mx-1">
                                                            Termenii si conditiile de utilizare
                                                        </Link>
                                                         a Inovia-Trans
                                                    </span>
                                                </label>
                                            </div>
                                        </>)}
                                <div className={`${loadedCui ? 'h-[40px]' : 'h-0'} flex flex-row gap-2`}>
                                    {
                                        !loadedCui ?
                                            <></> :
                                            <div
                                                onClick={() => {
                                                    setPage(page === 1 ? areFieldsEmpty(firmDetails) ? 1 : 2 : 1)
                                                }}
                                                className={`w-full h-full cursor-pointer text-white bg-accent-main-500 font-main-medium rounded-lg text-sm text-center gap-2 transition-all flex justify-center items-center mt-5`}>
                                                {buttonState()}
                                            </div>
                                    }
                                    <button type="submit"
                                            disabled={componentLoading || !termsAndConditionsAccepted}
                                            className={`${page === 2 ? 'w-full text-white' : 'w-0 text-transparent'} h-full bg-accent-main-500 disabled:bg-gray-300 font-main-medium rounded-lg text-sm text-center transition-all flex justify-center items-center mt-5`}>
                                        {(!componentLoading ? "Inregistreaza-te" : <Loading/>)}
                                    </button>
                                </div>
                                <Link to={ApplicationRoutes.login}
                                      className={"mt-7 flex text-sm justify-center items-center w-full text-center"}>
                                    <span className={"mr-1"}>Ai un cont?</span>
                                    <IoIosArrowRoundForward/>
                                </Link>
                            </form>
                        </div>
                    </div>
            }
        </div>
    );
}