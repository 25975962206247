import {HiOutlineSearch} from "react-icons/hi";
import {MdClear} from "react-icons/md";
import Loading from "../../../commons/component/loading/views/Loading";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import BlacklistService from "../../../services/BlacklistService";
import {Link, useLocation} from "react-router-dom";
import AiDocumentCheckerRouter from "../../../routers/AiDocumentCheckerRouter";
import {IoChevronBack} from "react-icons/io5";

export default function AiDocumentCheckerBlacklistSearch() {

    const {user} = useContext(AuthContext);
    const [isLoadingBlacklist, setIsLoadingBlacklist] = useState<boolean>(false);
    const [searchedText, setSearchedText] = useState<string>("");
    const [searchResults, setSearchResults] = useState<boolean | null>(null);
    const service = new BlacklistService();
    const location = useLocation();

    useEffect(() => {
        search();
    }, [searchedText]);

    const handleSearch = (searchTerm: string) => {
        setSearchedText(searchTerm);
    }

    const search = () => {
        if (user === null)
            return;

        if (searchedText.length === 0)
            return;

        setIsLoadingBlacklist(true);
        service.search(user.firmSlug, {searchQuery: searchedText})
            .then((_) => setSearchResults(true))
            .catch((_) => setSearchResults(false))
            .finally(() => setIsLoadingBlacklist(false));
    }

    return (
        <>
            <div className={"flex items-center gap-2 pb-4 h-[50px]"}>
                <Link
                    className={"p-3 text-white rounded-lg bg-accent-main-500"}
                    to={location.pathname.replace(AiDocumentCheckerRouter.blackListSearch, "")}>
                    <IoChevronBack/>
                </Link>
                <h1 className="text-text-main-color-500 text-xl font-main-bold">
                    Interogare blacklist
                </h1>
            </div>
            <div className={"rounded-xl bg-white p-4 flex flex-col justify-center items-center"}>
                <h1 className={"font-main-medium text-2xl pb-3 text-center"}>Blacklist Search</h1>
                <div
                    className="border group bg-white duration-300 focus-within:border-accent-main-500 w-full md:w-2/3 gap-2 rounded-xl flex justify-center items-center">
                    <HiOutlineSearch
                        className="text-gray-300 duration-300 group-focus-within:text-accent-main-500 pl-2 w-[30px]"/>
                    <input
                        value={searchedText}
                        onChange={(e) => handleSearch(e.target.value)}
                        type="text"
                        className="caret-accent-main-500 w-full outline-none p-2 rounded-full"
                        placeholder="Cauta aici orice detaliu"
                    />
                    {
                        searchedText.length !== 0 && (
                            <MdClear
                                onClick={() => handleSearch("")}
                                className="text-error pr-2 w-[30px]"
                            />
                        )
                    }
                </div>
                <div className={"w-full pt-3"}>
                    {
                        isLoadingBlacklist ?
                            <Loading color={"fill-accent-main-500"}/>
                            : searchResults
                                ? <div className={"text-error font-main-bold text-center"}>
                                    Furnizorul este in blacklist
                                </div>
                                : !searchResults
                                    ? <div className={"text-accent-main-500 font-main-bold text-center"}>
                                        Furnizorul nu este in
                                        blacklist
                                    </div>
                                    : <></>
                    }
                </div>
            </div>
        </>
    );
}